import React from "react";
import { GenericGrid, GenericTextInput, GenericNumberInput, GenericDateInput, GenericSelectInput, GenericAlert, GenericCheckInput } from "views/Components/Generic";
import { Card } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import DateHelper from "core/DateHelper";

import GridContainer from "components/Grid/GridContainer"
import GridItem from "components/Grid/GridItem";
import GridButton from "views/Components/GridButton";

import { DeleteIcon } from "core/Icons";
import Button from "components/CustomButtons/Button";
import LoadingComponent from "views/Components/LoadingComponent";

import { Proxy, withArtifex } from "core";

import {JSEncrypt} from "jsencrypt";
import TabsComponent from "views/Components/TabsComponent";
import ExternalContainer from "layouts/ExternalContainer";


class VisaDirectThirdPartyIframe extends React.Component {
	constructor(props) {
		super(props);

		this.marginLeft2 = { marginLeft: 2 };
		this.maxLength6 = 6;
		this.maxLength40 = 40;

		this.state = {
			customerModel: this.GetDefaultCustomerModel(null),
			newCardModel: this.GetDefaultNewCardModel(),
			vModel: {},
			alert: null,
			isLoading: true,
			list: [],

			IsCustomerExists: false,
			cardsList: [],

			selectedCardId: null,

			appSettings: {
				"IsModifyAllowed": false,
				"Base64Logo": null
			}
		};

		this.AcceptTermText = "Store my card on this computer for future use.";

	}

	GetColumnsRowStyleColor(status) {
		this.columnsRowStyleColor = {
			color: status != null && status != 0
				? "#FB3005" : ""
		};
		return this.columnsRowStyleColor;
	}

	GetColumnsRowStyle(status) {
		this.columnsRowStyle = {
			color: (status != null && status != 0 && status == this.state.selectedCardId)
				? "#FB3005" : "",
			fontSize: "14px"
		};
		return this.columnsRowStyle;
	}

	// get default values in constructor
	GetDefaultCustomerModel = (customerNumber) => {
		return {
			CustomerNumber: customerNumber,
			FirstName: null,
			MiddleName: null,
			LastName: null,
			Phone: null,
			AddressLine1: null,
			AddressLine2: null,
			PostalCode: null,
			CountryId: null,
			ProvinceId: null,
			CityId: null,
			BirthDateValue: null
		};
	}

	GetDefaultNewCardModel = () => {
		return {
			CardNumber: null,
			ExpireDate: null,
			CVV: null,
			AcceptTerms: false
		};
	}

	GetCustomerModel = (customerNumber) => {

		let searchList = this.state.list;

		return {
			CustomerNumber: customerNumber,
			FirstName: searchList[0]["FirstName"],
			MiddleName: searchList[0]["MiddleName"],
			LastName: searchList[0]["LastName"],
			PhoneNumber: searchList[0]["PhoneNumber"],
			AddressLine1: searchList[0]["AddressLine1"],
			AddressLine2: searchList[0]["AddressLine2"],
			PostalCode: searchList[0]["PostalCode"],
			CountryId: searchList[0]["CountryId"],
			ProvinceId: searchList[0]["ProvinceId"],
			CityId: searchList[0]["CityId"],
			BirthDateValue: searchList[0]["BirthDateValue"]
		};
	}

	GetCustomerCards = (customerNumber) => {

		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/GetCardsForIframe",
			customerNumber,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				
				let paymentInstruments = responseData.Item["PaymentInstrumentList"];
				const pan = "**** **** ****";
				let cards = this.state.cardsList;

				for (var i = 0; i < paymentInstruments.length; i++) {
					let card = {
						"CardId": paymentInstruments[i].CardId,
						"Last4Digits": pan + " " + paymentInstruments[i].Last4Digits,
						"CardholderName": paymentInstruments[i].CardholderName,
						"MaskedCardNumber" : paymentInstruments[i].MaskedCardNumber,
						"PaymentInstrumentId": paymentInstruments[i].PaymentInstrumentId
					};
					cards.push(card);
				}

				this.setState({ cardsList: cards });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	// change events
	HandleChangeCustomerModel = (name, value, data) => {
		this.setState(function (state, props) {
			var temp = state.customerModel || {};
			temp[name] = value;
			return { customerModel: temp };
		});
	}

	HandleChangeNewCardModel = (name, value, data) => {
		this.setState(function (state, props) {
			var temp = state.newCardModel || {};
			temp[name] = value;
			return { newCardModel: temp };
		});
	}

	HandleRowDelete = (paymentInstrumentId) => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/DeletePaymentInstrument",
			paymentInstrumentId,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				
				this.setState(function (state, props) {
					var temp = this.state.cardsList;
					temp = this.state.cardsList.filter(card => card.PaymentInstrumentId != paymentInstrumentId);
					return { cardsList: temp };
				});
				this.ShowMessage("success", "Success", "Operation is successfull!")
				this.setState({ isLoading: false });
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	// Clear Customer Fields
	HandleCustomerClear = () => {
		let model = this.state.customerModel;
		let customerNumber = model.CustomerNumber;

		this.setState({ customerModel: this.GetDefaultCustomerModel(customerNumber) });
	}

	// Clear Card Info
	HandleNewCardClear = () => {
		this.setState({ newCardModel: this.GetDefaultNewCardModel() });
	}

	// Show Messages functions
	ShowMessage = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.setState({ alert: "" })} OnConfirm={onConfirm} />
		});
	}

	ShowConfirmDelete = (onConfirm) => {
		this.ShowConfirmMessage("warning", "Warning", "Delete this Credit Card?", onConfirm);
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({ alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.setState({ alert: "" })} /> });
	}

	// Validation functions
	ValidateCustomer = () => {
		const { customerModel, vModel } = this.state;
		const errors = [];

		if (!customerModel.FirstName) {
			errors.push("First Name is required.");
			vModel.FirstName = true;
		} else {
			vModel.FirstName = false;
		}

		if (!customerModel.LastName) {
			errors.push("Last Name is required.");
			vModel.LastName = true;
		} else {
			vModel.LastName = false;
		}

		if (!customerModel.AddressLine1) {
			errors.push("Address is required.");
			vModel.AddressLine1 = true;
		} else {
			vModel.AddressLine1 = false;
		}

		if (!customerModel.PostalCode) {
			errors.push("Postal Code is required.");
			vModel.PostalCode = true;
		} else {
			vModel.PostalCode = false;
		}

		if (!customerModel.CountryId) {
			errors.push("Country is required.");
			vModel.CountryId = true;
		} else {
			vModel.CountryId = false;
		}

		if (!customerModel.ProvinceId) {
			errors.push("Province is required.");
			vModel.ProvinceId = true;
		} else {
			vModel.ProvinceId = false;
		}

		if (!customerModel.CityId) {
			errors.push("City is required.");
			vModel.CityId = true;
		} else {
			vModel.CityId = false;
		}

		this.setState({ vModel });

		if (errors.length > 0) {
			this.ShowMessageNode("warning", "Check these fields!", errors.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	ValidateNewCard = () => {
		const { newCardModel, vModel } = this.state;
		const errors = [];

		if (!newCardModel.CardNumber) {
			errors.push("Card Number is required.");
			vModel.CardNumber = true;
		} else {
			vModel.CardNumber = false;
		}

		if (!newCardModel.ExpireDate) {
			errors.push("Expire Date is required.");
			vModel.ExpireDate = true;
		} else {
			vModel.ExpireDate = false;
		}

		if (!newCardModel.CVV) {
			errors.push("CVV is required.");
			vModel.CVV = true;
		} else {
			vModel.CVV = false;
		}

		if (!newCardModel.AcceptTerms) {
			errors.push("Accept Terms must be approved.");
		}

		this.setState({ vModel });

		if (errors.length > 0) {
			this.ShowMessageNode("warning", "Check these fields!", errors.map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		return true;
	}

	// Search customer
	SearchCustomer = (customerNumber) => {

		this.setState({ isLoading: true });

		let model = this.state.customerModel;
		model.CustomerNumber = customerNumber;

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/RegisteredClientsSearchAccount",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ list: responseData.Item || [] });

				let list = this.state.list;
				if (list.length > 0) {
					// customer found, get customer model
					this.setState({
						customerModel: this.GetCustomerModel(customerNumber),
						IsCustomerExists: true
					});
				}
				else {
					// customer not found, setup default customer model
					// with TrackingID
					this.ShowMessage("warning", "Load Customer Info", "Customer with the number: "
						+ customerNumber + " was not found !");
				}
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	// Insert / Update  Customer
	InsertCustomer = () => {
		if (!this.ValidateCustomer())
			return;

		// save customer into database and
		// get new Customer Number
		let model = this.state.customerModel;
		model.CustomerNumber = "0";
		model.ClientTypeId = 8011;
		model.IsVisa = true;

		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/CreateCustomer",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				model.CustomerNumber = responseData.Item["CustomerNumber"];
				this.setState({ customerModel: model, list: responseData.Item || [], IsCustomerExists: true });

				window.parent.postMessage("CustomerNumber:" + responseData.Item["CustomerNumber"], this.state.appSettings.TargetOrigin);
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	// Update Customer Profile
	UpdateCustomer = () => {
		if (!this.ValidateCustomer())
			return;

		// update customer in the database	
		let model = this.state.customerModel;
		model.ClientTypeId = 8011; 

		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/UpdateCustomer",
			model,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				this.setState({ customerModel: model });
				this.ShowMessage("success", "Update Customer", "Customer with the number : "
					+ responseData.Item["CustomerNumber"]
					+ " has been successfull updated !")
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	// Card Methods
	SubmitCard = () => {
		if (!this.ValidateCustomer()) {
			return;
		}

		if (!this.ValidateNewCard()) {
			return;
		}

		// get Rsa Key
		this.GenerateRsaKey();
	}
	
	// Encryption
	GenerateRsaKey = () => {

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/GenerateRsaKey",
			null,
			(responseData) => {

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				let rsaKeyId = responseData.Item["RsaKeyId"];
				let rsaKey = responseData.Item["PublicKeyInPemFormat"];

				this.OnRsaKeyLoad(rsaKeyId, rsaKey);

			},
			(error) => {
				this.ShowMessage("error", "Error", error);
			},
		);
	}

	OnRsaKeyLoad(rsaKeyId, rsaKey) {

		let cardModel = this.state.newCardModel;
		let model = this.state.customerModel;
		let cards = this.state.cardsList;

		// @ts-ignore
		var crypt = new JSEncrypt();

		// encrypt
		crypt.setPublicKey(rsaKey);
		let encryptedVerificationCode = crypt.encrypt(cardModel.CVV);
		let encryptedPan = crypt.encrypt(cardModel.CardNumber.replaceAll(' ',''));

		// add payment instrument
		var newCard = {
			"customerNumber": model.CustomerNumber,
			"name": cardModel.CardholderName,
			"encryptedPan": encryptedPan,
			"encryptedVerificationCode": encryptedVerificationCode,
			"RsaKeyId": rsaKeyId,
			"expirationMonth": DateHelper.ToMoment(cardModel.ExpireDate).month() + 1,
			"expirationYear": DateHelper.ToMoment(cardModel.ExpireDate).year()
			//	"currenyId" : 147,
		}

		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/AddPaymentInstrument",
			newCard,
			(responseData) => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				let pan = "**** **** ****";
				let card = {
					"CardId": responseData.Item["CardId"],
					"Last4Digits": pan + " " + cardModel.CardNumber.substring(cardModel.CardNumber.length - 4),
					"CardholderName": cardModel.CardholderName,
					"MaskedCardNumber" : cardModel.CardNumber.substring(0,9) + "****" + cardModel.CardNumber.substring(cardModel.CardNumber.length - 4),
					"PaymentInstrumentId": responseData.Item["PaymentInstrumentId"]
				}
				cards.push(card);

				this.setState({ newCardModel: this.GetDefaultNewCardModel(), cardsList: cards });
				this.ShowMessage("success", "Submit New Card", "Credit Card has been successfull submitted !");
			},
			(error) => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	// Custom functions
	IsNullOrEmpty(value) {
		return (value == null || value === "" || value === ''
			|| value === '""' || value === '""');
	}

	JavascriptgetURLParameterValues(parameterName, url) {
		if (!url) url = window.location.href;
		parameterName = parameterName.replace(/[\[\]]/g, "\\$&amp;");
		var regularExpression =
			new RegExp("[?&amp;]" + parameterName + "(=([^&amp;#]*)|&amp;|#|$)"),
			results = regularExpression.exec(url);
		if (!results) return null;
		if (!results[2]) return '';
		return decodeURIComponent(results[2].replace(/\+/g, " "));
	}

	componentDidMount() {
		this.loginWithTrackingId();
	}

	getBase64Logo(trackingId){

		this.setState({ isLoading: true });

		Proxy.POST(
			"/bankapi/v1.0/VisaDirect/SearchIframeApiKeyList",
			{ EncryptedApiKey: trackingId },
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				if (responseData.Item !== null) {
					let appSett = this.state.appSettings;
					appSett.Base64Logo = responseData.Item[0].Base64Logo;
					this.setState({ appSettings: appSett });
				}
				
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);


	}

	loginWithTrackingId() {
		this.setState({ isLoading: true });
		let trackingId = this.JavascriptgetURLParameterValues("TrackingID");
		Proxy.POST(
			"/coreapi/v1.0/Authentication/VisaDirectThirdPartyLogin",
			trackingId,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}

				const user = {
					SessionId: responseData.DCBankAccessToken, ForceTwoFactorAuth: responseData.ForceTwoFactorAuth,
					SessionStatus: responseData.SessionStatus
				};

				localStorage.setItem("user", JSON.stringify({ SessionId: user.SessionId }));
				let appSett = this.state.appSettings;
				appSett.IsModifyAllowed = true;
				this.setState({ appSettings: appSett });

				let customerNumber = this.JavascriptgetURLParameterValues("CustomerNumber");
				if(!this.IsNullOrEmpty(customerNumber)) {
					this.SearchCustomer(customerNumber);
					this.GetCustomerCards(customerNumber);
				}
				else{
					this.setState({ customerModel: this.GetDefaultCustomerModel(customerNumber) });
				}
				this.getBase64Logo(trackingId);
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}

	handleChangeParentTab(tab) { }

	render() {

		const { customerModel, newCardModel, vModel, alert, isLoading, appSettings, list } = this.state;

		return (
			<ExternalContainer Title="Visa Direct" Subtitle="Process Card Transactions Securely" HalfWidth={false} Base64Logo={appSettings.Base64Logo} >
				<LoadingComponent Show={isLoading} />
				{alert}
				<GridContainer justify="center">
					<GridItem xs={12} sm={11} md={10}>
						<Card style={{ border: "1px solid gray", borderRadius: "2px", marginBlockEnd: "7px", overflow: "hidden" }}>
							<CardBody>
								<GridItem xs={12}>
									<TabsComponent
										TabIndex={this.state.ParentTabIndex}
										handleChange={this.handleChangeParentTab(false)}
										tabList={[
											{
												tabButton: "My Profile",
												tabContent: (
													<GridContainer>
														<GridItem xs={2}></GridItem>
														<GridItem xs={4}>
															<GenericTextInput
																Name="FirstName"
																LabelText="First Name"
																Value={customerModel.FirstName || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																Required
																IsInvalid={vModel.FirstName}
															   Disabled={!appSettings.IsModifyAllowed}
															/>
															<GenericTextInput
																Name="MiddleName"
																LabelText="Middle Name"
																Value={customerModel.MiddleName || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																Disabled={!appSettings.IsModifyAllowed}
															/>
															<GenericTextInput
																Name="LastName"
																LabelText="Last Name"
																Value={customerModel.LastName || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																Required
																IsInvalid={vModel.LastName}
																Disabled={!appSettings.IsModifyAllowed}
															/>
															<GenericDateInput
																Utc
																Name="BirthDateValue"
																LabelText="Date of Birth"
																Value={customerModel.BirthDateValue || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																IncludeTime={false}
																Required
																IsInvalid={vModel.BirthDateValue}
																Disabled={!appSettings.IsModifyAllowed}
															/>
															<GenericNumberInput
																IsPhone
																Name="PhoneNumber"
																LabelText="Phone"
																Format="(###) ### ####"
																InputStyle={this.marginLeft2}
																Value={customerModel.PhoneNumber || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																MaxLength={10}
																Disabled={!appSettings.IsModifyAllowed}
															/>
														</GridItem>
														<GridItem xs={4}>
															<GenericTextInput
																Name="AddressLine1"
																LabelText="Address Line 1"
																Id="street-address"
																Required
																Value={customerModel.AddressLine1 || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																inputProps={{ maxLength: this.maxLength40 }}
																Disabled={!appSettings.IsModifyAllowed}
																IsInvalid={vModel.AddressLine1}
															/>
															<GenericTextInput
																Name="AddressLine2"
																Id="street-address2"
																LabelText="Address Line 2"
																Value={customerModel.AddressLine2 || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																inputProps={{ maxLength: this.maxLength40 }}
																Disabled={!appSettings.IsModifyAllowed}
															/>
															<GenericTextInput
																Name="PostalCode"
																LabelText="Postal Code"
																Id="postcode"
																Value={customerModel.PostalCode || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																inputProps={{ maxLength: this.maxLength6 }}
																Required
																IsInvalid={vModel.PostalCode}
																Disabled={!appSettings.IsModifyAllowed}
															/>
															{ appSettings.IsModifyAllowed &&
															<GenericSelectInput
																key={appSettings.IsModifyAllowed}
																Name="CountryId"
																LabelText="Country"
																Method="POST"
																Url={appSettings.IsModifyAllowed ? "/coreapi/v1.0/Country/GetAll" : "" }
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember={"Code"}
																Sorted={{ Member: "OrderIndex" }}
																RenderItem={d => `+${d.Code} (${d.Name})`}
																Required
																Value={customerModel.CountryId || ""}
																ValueChanged={this.HandleChangeCustomerModel}
																Disabled={!appSettings.IsModifyAllowed}
																IsInvalid={vModel.CountryId}
															/> }
															{ appSettings.IsModifyAllowed &&
															<GenericSelectInput
																key={customerModel.CountryId + "province"}
																Name="ProvinceId"
																LabelText="Province"
																Method="POST"
																Url={appSettings.IsModifyAllowed ? "/coreapi/v1.0/Province/Filter" : "" }
																Parameter={{
																	"CountryId": customerModel.CountryId
																}}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="Name"
																Required
																Value={customerModel.ProvinceId}
																ValueChanged={this.HandleChangeCustomerModel}
																Disabled={!appSettings.IsModifyAllowed}
																IsInvalid={vModel.ProvinceId}
															/> }
															{ appSettings.IsModifyAllowed &&
															<GenericSelectInput
																key={"city" + customerModel.ProvinceId + ""}
																Name="CityId"
																LabelText="City"
																Method="POST"
																Url={appSettings.IsModifyAllowed ? "/coreapi/v1.0/City/Filter" : "" }
																Parameter={{
																	"CountryId": customerModel.CountryId,
																	"ProvinceId": customerModel.ProvinceId
																}}
																DataRoot="Item"
																KeyValueMember="Id"
																TextValueMember="Name"
																Required
																Value={customerModel.CityId}
																ValueChanged={this.HandleChangeCustomerModel}
																Disabled={!appSettings.IsModifyAllowed}
																IsInvalid={vModel.CityId}
															/> }														
														</GridItem>
														<GridContainer justify="flex-end">
															<GridItem style={{ float: "right", marginRight: "12px" }}>
																<div hidden={!appSettings.IsModifyAllowed}>
																	<Button size="sm" color="black" onClick={() => this.HandleCustomerClear()}>Clear</Button>
																	<Button size="sm" color="black" onClick={this.state.IsCustomerExists ? () => this.UpdateCustomer() : () => this.InsertCustomer()}
																	>
																		{this.state.IsCustomerExists ? "Update" : "Add"}
																	</Button>
																</div>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
																<br></br>
															</GridItem>
														</GridContainer>
													</GridContainer>
												),
												Hidden: false,

											},
											{
												tabButton: "My Cards",
												tabContent: (

													<GridContainer>
														<GridItem xs={3}></GridItem>
														<GridItem xs={6}>
															<GenericTextInput
																Name="CardholderName"
																LabelText="Cardholder Name"
																Required
																Value={newCardModel.CardholderName || ""}
																ValueChanged={this.HandleChangeNewCardModel}
																Disabled={!this.state.IsCustomerExists && !appSettings.IsModifyAllowed}
																IsInvalid={vModel.CardholderName}
															/>
															<GenericTextInput
																Name="CardNumber"
																LabelText="Card Number"
																Required
																Format="#### #### #### ####"
																Value={newCardModel.CardNumber || ""}
																ValueChanged={this.HandleChangeNewCardModel}
																Disabled={!this.state.IsCustomerExists && !appSettings.IsModifyAllowed}
																IsInvalid={vModel.CardNumber}
															/>
															<GenericDateInput
																Name="ExpireDate"
																LabelText="Expiration Date"
																DateFormat={"MM/YY"}
																Value={newCardModel.ExpireDate ? DateHelper.ToDateInputValue(newCardModel.ExpireDate) : ""}
																ValueChanged={this.HandleChangeNewCardModel}
																Disabled={!this.state.IsCustomerExists && !appSettings.IsModifyAllowed}
																IsInvalid={vModel.ExpireDate}
																Required
																IncludeTime={false}
																Utc
															/>
															<GenericTextInput
																Name="CVV"
																LabelText="Card Security Code"
																Required
																Format="###"
																Value={newCardModel.CVV || ""}
																ValueChanged={this.HandleChangeNewCardModel}
																Disabled={!this.state.IsCustomerExists && !appSettings.IsModifyAllowed}
																IsInvalid={vModel.CVV}
															/>
															<GenericCheckInput
																Name="AcceptTerms"
																LabelText={this.AcceptTermText}
																IsTextLeft
																Value={newCardModel.AcceptTerms}
																Disabled={!appSettings.IsModifyAllowed}
																ValueChanged={this.HandleChangeNewCardModel} />

															<GridContainer justify="flex-end">
																<GridItem style={{ float: "right" }}>
																	<Button size="sm" color="black" disabled={!this.state.IsCustomerExists && !appSettings.IsModifyAllowed} onClick={() => this.HandleNewCardClear()}>Clear</Button>
																	<Button size="sm" color="black" disabled={!this.state.IsCustomerExists && !appSettings.IsModifyAllowed} onClick={() => this.SubmitCard()}>ADD</Button>
																</GridItem>
															</GridContainer>

															<br></br>
															<br></br>
															<br></br>

															<GenericGrid
																Data={this.state.cardsList}
																HideButton={true}
																style={{ borderCollapse: "collapse", borderRadius: "1em" }}
																Columns={[
																	{
																		Header: "Action",
																		accessor: "Actions",
																		width: 75,
																		Cell: row => (
																			<div>
																				<GridButton
																					tooltip="Delete"
																					Icon={DeleteIcon}
																					Disabled={false}
																					OnClick={() => this.ShowConfirmDelete(() => this.HandleRowDelete(row.original.PaymentInstrumentId))}
																				/>
																			</div>
																		),
																		sortable: false,
																		filterable: false
																	},
																	{
																		Header: "Name on the Card",
																		accessor: "CardholderName",
																		sortable: false,
																		filterable: false,
																		Cell: row => (<div>{row.original.CardholderName}</div>)
																	},
																	{
																		Header: "Card Token",
																		accessor: "CardToken",
																		sortable: false,
																		filterable: false,
																		Cell: row => (<div>{row.original.MaskedCardNumber}</div>)
																	},


																]}
															/>

														</GridItem>
														<GridItem xs={3}></GridItem>
													</GridContainer>
												),
												Hidden: false,
											}
										]}
									/>
									<GridContainer />
								</GridItem>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>


			</ExternalContainer>
		)
	}
}

export default withArtifex(VisaDirectThirdPartyIframe, {});