import { DialogContent, DialogTitle, List, Slide } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericDialog, GenericCheckInput, GenericDialogActions, GenericEmailInput, GenericNumberInput, GenericExpansionPanel, GenericLabel, GenericSelectInput, GenericTitle, GenericGrid, GenericRadioInput } from "views/Components/Generic";
import LoadingComponent from "views/Components/LoadingComponent";
import { GridColumnType, ProgramCodes, MenuCodes } from "views/Constants/Constant";
import GridButton from "views/Components/GridButton";
import { DeleteIcon, ApproveIcon, UndoIcon } from "core/Icons";
import CardHeader from "components/Card/CardHeader";
import ClientHelper from "core/ClientHelper";
import GenericTextInput from "../../Components/Generic/GenericTextInput";

function Transition(props) {
	return <Slide direction="up" {...props} />;
}

class CreateInteracETransferUser extends React.Component {
	constructor(props) {
		super(props);
		this.initialModel = {
			ClientId: 0
		};
		this.state = {
			model: this.initialModel,
			vModel: {},
			isLoading: false,
			isConfirmMainUserDialogOpen: false,
			isConfirmAdrDialogOpen: false,
			isConfirmAdrDialogOpenUpdate: false,
			isConfirmDeleteDialogOpen: false,
			isConfirmStoreDialogOpen: false,
			isConfirmDeleteStoreDialogOpen: false,
			list: [],
			directDepositRegistrationId: 0,
			storeId: 0,
			accountSelectKey: false,

			ServiceType: "EMAIL",
			isBackOffice: false,
			autoDepositAccountIdForDcRequestMoney: 0,
			isConfirmDcRequestMoneyDialogOpen: false,
			gatewayUrl: false,
			fulFillDelayTurnedOn: false,
			fulFillDelayMaxWait: 0,
			fulFillDelayAutoReject: false
		};
		this.parameterYesNo = {
			ParameterCode: "YesNo"
		};
	}
	componentDidMount() {
		this.props.setAppLeftTitle("e-Transfer Set Up");
		if (this.props.MenuCode == MenuCodes.Backoffice_ClientSetupAutoDeposit) {
			this.props.setAppCenterTitle("CLIENT");
		} else {
			this.props.setAppCenterTitle("WALLET");
		}

		var { model, isBackOffice } = this.state;
		var initialModel = this.initialModel;

		isBackOffice = ClientHelper.IsBackOffice();
		if (!isBackOffice) {
			model.ClientId = ClientHelper.GetClientRowId();
			initialModel.ClientId = model.ClientId;
		}
		this.setState({ model, isBackOffice });
		if (!isBackOffice)
			this.GetInteracUserDetail(model.ClientId);
	}

	GetInteracUserDetail = (clientId) => {
		var { model, accountSelectKey } = this.state;
		var initialModel = this.initialModel;
		var list = this.state.list;
		var storeList = this.state.storeList;
		var clientSettings = this.state.ClientSettings;

		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/GetInteracUserDetail",
			{ ClientId: clientId },
			responseData => {
				this.hideAlert();
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				if (responseData.Item != null) {
					model.MainUserEmail = responseData.Item.CustomerETransfer?.EMail ?? "";
					model.GatewayUrlTurnedOff = responseData.Item.ClientSettings.GatewayUrlTurnedOff;
					model.FulFillDelayTurnedOn = responseData.Item.ClientSettings.FulFillDelayTurnedOn;
					model.FulFillDelayMaxWait = responseData.Item.ClientSettings.FulFillDelayMaxWait;
					model.FulFillDelayAutoReject = responseData.Item.ClientSettings.FulFillDelayAutoReject;
					initialModel.MainUserEmail = model.MainUserEmail;
					list = responseData.Item.DdrList;
					storeList = responseData.Item.StoreList;
					model.templateId = responseData.Item.StoreList?.find(x => x.IsClientMainAccount && x.IsRecordValid)?.BulkTemplateId;
				}
				this.setState({ model, list, storeList, clientSettings, accountSelectKey: !accountSelectKey, isLoading: false });
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}

	InsertOrUpdateIntearacMainUserValidate = () => {
		var ErrorList = [];
		const { model, vModel } = this.state;

		if (model.ClientId == null || model.ClientId == 0) {
			vModel.ClientId = true;
			ErrorList.push("Client cannot be be null !");
		}

		if (model.MainUserEmail == null || model.MainUserEmail == undefined || model.MainUserEmail == "") {
			ErrorList.push("Interac Main User Email must be defined !");
			vModel.MainUserEmail = true;
		}
		else {
			if (vModel.MainUserEmailValid) {
				ErrorList.push("Interac Main User Email is invalid !");
				vModel.MainUserEmail = true;
			}
		}

		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please control fields!", ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ isConfirmMainUserDialogOpen: true });
	};

	InsertOrUpdateMainUser = () => {
		this.setState({ isConfirmAdrDialogOpen: false, isLoading: true });
		var { model } = this.state;

		model.IsClientMainAccount = true;
		model.IsMainUser = true;
		model.InteracType = "EMAIL";

		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/InsertOrUpdateInteracUser",
			model,
			responseData => {
				this.hideAlert();

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "The operation completed successfully.");
				this.ClearData();
				this.GetInteracUserDetail(model.ClientId);
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}

	InsertOrUpdateIntearacUserValidate = () => {
		var ErrorList = [];
		const { model, vModel, list } = this.state;

		if ( (model.InteracEMail == null || model.InteracEMail == undefined || model.InteracEMail == "") && (this.state.ServiceType == "EMAIL" || this.state.ServiceType == "PHONE")) {
			ErrorList.push("Registration Email must be defined !");
			vModel.InteracEMail = true;
		}
		else {
			if (vModel.InteracEMailValid) {
				ErrorList.push("Registration Email is invalid !");
				vModel.InteracEMail = true;
			}
		}

		if ( (model.PhoneCountryCodeId == null || model.PhoneCountryCodeId == undefined || model.PhoneCountryCodeId == "") && this.state.ServiceType == "PHONE") {
			ErrorList.push("Phone Number[Country Code] must be defined !");
			vModel.PhoneCountryCodeId = true;
		}
		if ( (model.PhoneNumber == null || model.PhoneNumber == undefined || model.PhoneNumber == "") && this.state.ServiceType == "PHONE") {
			ErrorList.push("Phone Number must be defined !");
			vModel.PhoneNumber = true;
		}

		var existEmailControl = list.some(k => k.EMail == model.InteracEMail && k.ServiceType=="EMAIL" && (k.DeleteStatus == 1));
		if(this.state.ServiceType == "EMAIL" && existEmailControl) {			
			ErrorList.push("The Registration Email is already in use !");
			vModel.InteracEMail = true;
		}
		var existPhoneControl = list.some(k => k.Phone == model.InteracPhone && k.ServiceType=="PHONE" && (k.DeleteStatus == 1));
		if(this.state.ServiceType == "PHONE" && existPhoneControl) {			
			ErrorList.push("The Registration Phone is already in use !");
			vModel.InteracEMail = true;
		}

		vModel.SourceAccountId = model.SourceAccountId == null || (model.SourceAccountId <= 0);
		if (vModel.SourceAccountId)
			ErrorList.push("AutoDeposit Wallet must be defined !");

		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		if(existPhoneControl && this.state.ServiceType == "PHONE" )
		{
			this.setState({ isConfirmAdrDialogOpenUpdate: true });
		}
		else{
		this.setState({ isConfirmAdrDialogOpen: true });
		}
	};
	InsertOrUpdateeTransferClientSettings = () => {
		this.setState({ isLoading: true });
		let {model, GatewayUrlTurnedOff} = this.state;
		
		model.gatewayUrlTurnedOff = GatewayUrlTurnedOff;
		
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/UpdateETransferClientSettings",
			model,
			responseData => {
				this.hideAlert();
				
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while updating eTransfer Client Settings " + responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "eTransfer Client Settings updated successfully.");
				this.ClearData();
				this.GetInteracUserDetail(model.ClientId);
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while updating eTransfer Client Settings");
			}
	);
	};
	
	InsertOrUpdateIntearacUser = () => {
		this.setState({ isConfirmAdrDialogOpen: false, isLoading: true });
		var { model, MainUserEmail } = this.state;

		if (model.InteracEMail == MainUserEmail)
			model.IsClientMainAccount = true;
		else
			model.IsClientMainAccount = false;

		model.IsMainUser = false;

		model.InteracType = this.state.ServiceType;

		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/InsertOrUpdateInteracUser",
			model,
			responseData => {
				this.hideAlert();

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Interac User created successfully.");
				this.ClearData();
				this.GetInteracUserDetail(model.ClientId);
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({ isLoading: false, alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} /> });
		this.setState({});
	}

	handleChange = (name, newValue, data) => {
		this.setState(function (state) {
			var model = state.model || {};
			var accountSelectKey = this.state;
			var vModel = state.vModel || {};
			model[name] = newValue;

			if (name == "MainUserEmail" && data != null)
				vModel.MainUserEmailValid = !data.IsValid;

			if (name == "InteracEMail" && data != null)
				vModel.InteracEMailValid = !data.IsValid;

			if (name == "SourceAccountId" && data != null)
				model.ProgramId = data.ProgramId;

			if (name == "ClientId") {
				accountSelectKey = !accountSelectKey;
				model.StoreId = null;
			}

			if (name == "PhoneCountryCodeId" || name == "PhoneNumber") {
				model.InteracPhone = model.PhoneCountryCodeId + model.PhoneNumber;
			}


			return { model: model, vModel: vModel, accountSelectKey };
		});
		if (name == "ClientId" && this.state.isBackOffice) {
			this.GetInteracUserDetail(newValue);
		}
	}

	handleDelete = (id) => {
		if (id == null || id == 0)
			this.props.showMessage("error", "Error", "Record is invalid !");

		this.setState({ isConfirmDeleteDialogOpen: true, directDepositRegistrationId: id });
	}

	confirmDelete = () => {
		this.hideAlert();
		this.showLoading();
		var { model } = this.state;
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/DeleteDirectDepositRegistration",
			this.state.directDepositRegistrationId,
			responseData => {
				this.hideAlert();
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				this.GetInteracUserDetail(model.ClientId);
				this.hideAlert();
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while interac request ");
			}
		);	
	}

	confirmSetForDcRequestMoney = () => {
		this.hideAlert();
		this.showLoading();
		
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/SetAutoDepositAccountForDcRequestMoney",
			{ Id: this.state.autoDepositAccountIdForDcRequestMoney},
			responseData => {
				this.hideAlert(responseData);
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.GetInteracUserDetail(this.state.model.ClientId);
				this.hideAlert();
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred");
			}
		);
	}

	handleDeleteStore = (id) => {
		if (id == null || id == 0)
			this.props.showMessage("error", "Error", "Record is invalid !");

		this.setState({ isConfirmDeleteStoreDialogOpen: true, storeId: id });
	}

	confirmDeleteStore = () => {
		this.hideAlert();
		this.showLoading();
		var { model } = this.state;
		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/DeleteInteracStore",
			this.state.storeId,
			responseData => {
				this.hideAlert();
				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				this.GetInteracUserDetail(model.ClientId);
				this.hideAlert();
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}

	ClearData = () => {
		var initialModel = this.initialModel;
		initialModel.InteracEMail = null;
		initialModel.PhoneCountryCodeId = null;
		initialModel.PhoneNumber = null;
		initialModel.SourceAccountId = null;
		this.setState({ alert: null, isLoading: false, model: this.initialModel, isConfirmMainUserDialogOpen: false, isConfirmAdrDialogOpen: false, isConfirmAdrDialogOpenUpdate: false, isConfirmDeleteDialogOpen: false, isConfirmStoreDialogOpen: false, isConfirmDeleteStoreDialogOpen: false });
	}

	InsertInteracStoreValidate = () => {
		var ErrorList = [];
		const { model, vModel } = this.state;

		if (model.ClientId == null || model.ClientId == 0) {
			vModel.ClientId = true;
			ErrorList.push("Client cannot be be null !");
		}

		if (model.MainUserEmail == null || model.MainUserEmail == undefined || model.MainUserEmail == "") {
			ErrorList.push("Interac Main User Email must be defined !");
			vModel.MainUserEmail = true;
		}
		else {
			if (vModel.MainUserEmailValid) {
				ErrorList.push("Interac Main User Email is invalid !");
				vModel.MainUserEmail = true;
			}
		}

		if (model.StoreId == null || model.StoreId <= 0) {
			vModel.Store = true;
			ErrorList.push("Store cannot be null !");
		}

		this.setState({ vModel });
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please control fields!", ErrorList.slice(0, 10).map((x, i) => <div key={i}>{x}</div>));
			return false;
		}
		this.setState({ isConfirmStoreDialogOpen: true });
	}

	InsertInteracStore = () => {
		this.setState({ isConfirmAdrDialogOpen: false, isLoading: true });
		this.setState({ isConfirmAdrDialogOpenUpdate: false, isLoading: true });
		var { model, MainUserEmail } = this.state;

		if (model.InteracEMail == MainUserEmail)
			model.IsClientMainAccount = true;
		else
			model.IsClientMainAccount = false;

		model.IsMainUser = false;

		Proxy.POST("/bankapi/v1.0/BankInteracETransfer/InsertInteracStore",
			model,
			responseData => {
				this.hideAlert();

				if (!responseData.IsSucceeded) {
					this.props.showMessage("error", "Error", "An error occurred while interac request " + responseData.ErrorDescription);
					return;
				}
				this.props.showMessage("success", "Success", "Interac Store created successfully.");
				this.ClearData();
				this.GetInteracUserDetail(model.ClientId);
			},
			e => {
				this.hideAlert();
				this.props.showMessage("error", "Error", "An error occurred while interac request ");
			}
		);
	}
	ValueChangedForServiceType = (name, value) => {
		this.setState({ [name]: value });
	}

	ValueChangedForGatewayUrl = (name, value) => {
		this.setState({ [name]: value });
	}

	UpdateBulkEmailTemplate = () => {
		const body = {
			BulkTemplateId: this.state.model.templateId,
			CustomerETransferId: this.state.storeList?.find(x => x.IsClientMainAccount && x.IsRecordValid)?.Id
		};

		const clientId = this.state.model.ClientId;

		if(body.BulkTemplateId){
			Proxy.POST("/bankapi/v1.0/BankInteracETransfer/UpdateBulkTemplateId",
				body,
				responseData => {
					this.hideAlert();

					if (!responseData.IsSucceeded) {
						this.props.showMessage("error", "Error", "An error occurred. " + responseData.ErrorDescription);
						return;
					}
					this.props.showMessage("success", "Success", "Bulk template Id is stored successfully");
					this.ClearData();
					this.GetInteracUserDetail(clientId);
				},
				e => {
					this.hideAlert();
					this.props.showMessage("error", "Error", "An error occurred while updating bulk template id");
				}
			);
		}
	}

	hideAlert = () => { this.setState({ alert: null, isLoading: false, isConfirmMainUserDialogOpen: false, isConfirmAdrDialogOpen: false,isConfirmAdrDialogOpenUpdate:false, isConfirmStoreDialogOpen: false, isConfirmDeleteDialogOpen: false, isConfirmDeleteStoreDialogOpen: false, isConfirmDcRequestMoneyDialogOpen: false }); }
	showLoading = () => { this.setState({ isLoading: true }); }

	render() {
		const { model, isConfirmMainUserDialogOpen, isConfirmAdrDialogOpen, isConfirmAdrDialogOpenUpdate, isConfirmDeleteDialogOpen, isConfirmDcRequestMoneyDialogOpen, vModel, list, accountSelectKey, isBackOffice, isConfirmStoreDialogOpen, storeList, isConfirmDeleteStoreDialogOpen } = this.state;
		const { Disabled } = this.props;

		return (
			<div>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />

				<GenericDialog open={isConfirmMainUserDialogOpen} onBackdropClick={this.hideAlert} maxWidth="sm" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Create Interac Main User" Bold={true} />
					</DialogTitle>
					<DialogContent>
						Do you want to save this Interac main user?
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.InsertOrUpdateMainUser}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isConfirmMainUserDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isConfirmAdrDialogOpen} onBackdropClick={this.hideAlert} maxWidth="sm" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Create New AutoDeposit Registration" Bold={true} />
					</DialogTitle>
					<DialogContent>
						Do you want to add this AutoDeposit registration?
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.InsertOrUpdateIntearacUser}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isConfirmAdrDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isConfirmAdrDialogOpenUpdate} onBackdropClick={this.hideAlert} maxWidth="sm" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Update AutoDeposit Registration" Bold={true} />
					</DialogTitle>
					<DialogContent>
						This email is already registered. Do you want to update this AutoDeposit registration?
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.InsertOrUpdateIntearacUser}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isConfirmAdrDialogOpenUpdate: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				<GenericDialog open={isConfirmStoreDialogOpen} onBackdropClick={this.hideAlert} maxWidth="sm" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Create New Store" Bold={true} />
					</DialogTitle>
					<DialogContent>
						Do you want to add this Store registration?
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.InsertInteracStore}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isConfirmStoreDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<GenericDialog open={isConfirmDeleteDialogOpen} onBackdropClick={this.hideAlert} maxWidth="sm" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Delete AutoDeposit Registration" Bold={true} />
					</DialogTitle>
					<DialogContent>
						Do you want to delete this AutoDeposit registration record ?
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.confirmDelete}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isConfirmDeleteDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				
				<GenericDialog open={isConfirmDcRequestMoneyDialogOpen} onBackdropClick={this.hideAlert} maxWidth="sm" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Set AutoDeposit account for Dc Request Payment" Bold={true} />
					</DialogTitle>
					<DialogContent>
						Do you want to set this AutoDeposit account for DC Request Payment?
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.confirmSetForDcRequestMoney}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isConfirmDcRequestMoneyDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
				{/* Delete Store */}
				<GenericDialog open={isConfirmDeleteStoreDialogOpen} onBackdropClick={this.hideAlert} maxWidth="sm" fullWidth TransitionComponent={Transition}>
					<DialogTitle  >
						<GenericLabel FontSize="16px" Text="Delete Store Registration" Bold={true} />
					</DialogTitle>
					<DialogContent>
						Do you want to delete this Store registration record ?
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.confirmDeleteStore}>Confirm</Button>
						<Button size="sm" onClick={() => this.setState({ isConfirmDeleteStoreDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>

				<ButtonToolbar ButtonList={[
					{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
					{ Code: "Search", OnClick: () => this.GetInteracUserDetail(model.ClientId), Disabled: Disabled },
					{ Code: "Submit", OnClick: () => { this.UpdateBulkEmailTemplate(); this.InsertOrUpdateIntearacMainUserValidate() }, Disabled: Disabled }
				]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />

				<Card>
					<CardBody>
						<GenericExpansionPanel Title="Interac User Detail">
							<GridContainer>
								<br />
								<GridItem xs={4}>
									<GenericSelectInput
										Name="ClientId"
										LabelText="Client"
										Method="POST"
										Url="/bankapi/v1.0/BankCustomer/Search"
										Parameter={{}}
										DataRoot="Item"
										KeyValueMember="Id"
										RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
										Value={model.ClientId || ""}
										ValueChanged={this.handleChange}
										Disabled={!isBackOffice}
										Required
										IsInvalid={vModel.ClientId} /></GridItem>
								<GridItem xs={4} >
									<GenericEmailInput
										Name="MainUserEmail"
										LabelText="Interac Main User EMail"
										Value={model.MainUserEmail}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.MainUserEmail}
										Disabled={Disabled || !isBackOffice} />
								</GridItem>

								{/* <GridItem xs={4} > */}
								{/* {model.ClientId != null && model.ClientId > 0 && isBackOffice && model.MainUserEmail != null && model.MainUserEmail != "" &&
										<GenericSelectInput
											key={accountSelectKey + "x"}
											Name="StoreId"
											LabelText="Store"
											Method="POST"
											Url="/bankapi/v1.0/BankInteracETransfer/GetClientStoreForInteracRegistration"
											Parameter={model.ClientId}
											DataRoot="Item"
											KeyValueMember="Id"
											RenderItem={d => `${d.StoreCode} - ${d.StoreName}`}
											Value={model.StoreId}
											ValueChanged={this.handleChange}
											Sorted={{ Member: "StoreName" }}
											Required
											IsInvalid={vModel.Store} />} */}
								{/* </GridItem> */}
								{/* <GridItem xs={4} /> */}
								<GridItem xs={4}>
									<GenericTextInput
										Name='templateId'
										LabelText='Bulk Email Template Identifier'
										Value={this.state.model.templateId}
										ValueChanged={(name, value) => this.handleChange(name, value)}
										Disabled={!model?.ClientId}
										inputProps={{ maxLength: 16 }}
									/>
								</GridItem>
								<br/>	
								<br /><br /><br /><br />
							</GridContainer>
						</GenericExpansionPanel>
					</CardBody>
				</Card>
				<Card>
					<CardHeader>
						<GenericTitle text={"Client eTransfer settings"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericCheckInput
									Name="GatewayUrlTurnedOff"
									LabelText="Disable Gateway Url for Request Money"
									Value={model.GatewayUrlTurnedOff}
									ValueChanged={this.handleChange}
									Disabled={!isBackOffice}
								/>
							</GridItem>		
							<br/>
							<GridContainer justify="flex-start">
							</GridContainer>
								<GridItem xs={3}>
									<GenericCheckInput
										Name="FulFillDelayTurnedOn"
										LabelText="eTransfer Delay Turned On"
										Value={model.FulFillDelayTurnedOn}
										ValueChanged={this.handleChange}
										Disabled={!isBackOffice}
									/>
								</GridItem>
								<GridItem xs={3}>
									<GenericNumberInput
										Name="FulFillDelayMaxWait"
										LabelText="Maximum wait for delay (5-60min)"
										Value={model.FulFillDelayMaxWait}
										ValueChanged={this.handleChange}
										Disabled={!isBackOffice}
										DecimalScale={0}
									/>
								</GridItem>
								<GridItem xs={3}>
									<GenericCheckInput
										Name="FulFillDelayAutoReject"
										LabelText="Automatically reject after max time is reached (uncheck to accept)"
										Value={model.FulFillDelayAutoReject}
										ValueChanged={this.handleChange}
										Disabled={!isBackOffice}
									/>
								</GridItem>
								<br/>
								<GridContainer justify="flex-start">
									<GridItem>
										<Button disabled={!isBackOffice} size="sm" color="black" onClick={() => this.InsertOrUpdateeTransferClientSettings()}>Update eTransfer Client Settings</Button>
									</GridItem>
									<br/>
								</GridContainer>
						</GridContainer>
					</CardBody>
				</Card>
				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Auto-Deposit Registration"} />
					</CardHeader>
					<CardBody>
						<GridContainer>
							<GridItem xs={4}>
								<GenericSelectInput
									key={"key" + accountSelectKey}
									Name="SourceAccountId"
									Disabled={Disabled}
									Method="POST"
									Url="/bankapi/v1.0/BankInteracETransfer/GetClientFundAccountForDdr"
									Parameter={model.ClientId}
									DataRoot="Item"
									LabelText="AutoDeposit Wallet"
									Value={model.SourceAccountId || ""}
									KeyValueMember="Id"
									TextValueMember="DestinationAccount"
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.SourceAccountId} />
							</GridItem>
							<GridItem xs={4}>
								<GenericCheckInput Name="IsDcMoneyRequest" LabelText="DC Request Payment" ValueChanged={this.handleChange} Value={model.IsDcMoneyRequest} />
							</GridItem>
							<GridItem xs={4}></GridItem>

							<GridItem xs={4}>
								<GenericRadioInput
									Name="ServiceType"
									LabelText="Registration Service Type"
									IsStatic={true}
									StaticData={[{ Value: "EMAIL", Text: "Email" }, { Value: "PHONE", Text: "SMS" }]}
									KeyValueMember="Value"
									TextValueMember="Text"
									Value={this.state.ServiceType}
									ValueChanged={this.ValueChangedForServiceType}
									Disabled={false}
									IsFormControl={true}

								/>
							</GridItem>
							<GridItem xs={4}>
									<GenericEmailInput
										Name="InteracEMail"
										LabelText={this.state.ServiceType == "PHONE" ? "Contact Email" : "Registration Email Address"}
										Value={model.InteracEMail}
										ValueChanged={this.handleChange}
										Required
										IsInvalid={vModel.InteracEMail}
										Disabled={Disabled} />
								</GridItem>
								<br /><br /><br /><br />
							<GridItem xs={4} style={this.state.ServiceType == "PHONE" ? {} : { display: "none"}}>
								<GridContainer>
									<GridItem xs={8}>
										<GenericSelectInput
											Name="PhoneCountryCodeId"
											LabelText="Phone Number"
											TitleLabel="Country Code"
											LabelMd={6}
											Method="POST"
											Url="/coreapi/v1.0/Country/GetAll"
											Parameter={{}}
											DataRoot="Item"
											KeyValueMember="Code"
											TextValueMember={"Code"}
											Value={model.PhoneCountryCodeId == null || undefined ? "" : model.PhoneCountryCodeId}
											ValueChanged={this.handleChange}
											Sorted={{ Member: "OrderIndex" }}
											RenderItem={d => "+" + d.Code + " (" + d.Name + ")"}
										/>
									</GridItem>
									<GridItem xs={4} style={{ paddingRight: 2 + "px !important" }}>
										<GenericNumberInput
											IsPhone
											Name="PhoneNumber"
											InputStyle={{ marginLeft: 2 }}
											LabelMd={0}
											Value={model.PhoneNumber == null || undefined ? "" : model.PhoneNumber}
											ValueChanged={this.handleChange}
											MaxLength={10} />
									</GridItem>
								</GridContainer>
							</GridItem>
							<br /><br /><br /><br />
						</GridContainer>
						<GridContainer justify="flex-end">
							<GridItem>
								<Button disabled={Disabled} size="sm" color="black" onClick={() => this.InsertOrUpdateIntearacUserValidate()}>Add AutoDeposit</Button>
								{/* {isBackOffice && model.MainUserEmail != null && model.MainUserEmail != "" && <Button disabled={Disabled} size="sm" color="black" onClick={() => this.InsertInteracStoreValidate()}>Add Store</Button>} */}
								<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>Clear</Button>
							</GridItem>
							<br/>
						</GridContainer>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={list}
									Columns={[
										{
											Header: "Action",
											accessor: "Action",
											width: 75,
											Cell: row => (
												<div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'center', justifyContent: 'flex-start', alignItems: 'center'}}>
													<GridButton
														Icon={DeleteIcon}
														tooltip="Delete ADR Record"
														OnClick={() => { this.handleDelete(row.original.Id); }}
														Disabled={row.original.DeleteStatus == 2 ||
																(row.original.IsRegisteredToDirectDeposit != null
																	&& row.original.IsRegisteredToDirectDeposit == true
																	&& (row.original.DirectDepositReferenceNumber != null
																		|| row.original.DirectDepositReferenceNumber != undefined)
																	&& row.original.IsDirectDepositRegistrationDeletion == true
																) ? true :
																row.original.DeleteStatus == 1 ? false :
																	(
																		row.original.DeleteStatus == 3 ||
																			(row.original.DirectDepositReferenceNumber == null
																				&& row.original.DirectDepositRegistrationDate == null
																				&& (row.original.DirectDepositRegistrationStatus == 0 || row.original.DirectDepositRegistrationStatus == null)
																				&& (row.original.IsRegisteredToDirectDeposit == false || row.original.IsRegisteredToDirectDeposit == null)
																				&& (row.original.IsDirectDepositRegistrationDeletion == false || row.original.IsDirectDepositRegistrationDeletion == null)
																			)
																			? true : false
																	)
																}
															/>
															<GridButton
																Icon={row.original.IsDcMoneyRequest ? UndoIcon : ApproveIcon}
																tooltip={`${row.original.IsDcMoneyRequest ? 'Unset' : 'Set'} as DC Request Payment`}
																OnClick={() => { this.setState({ isConfirmDcRequestMoneyDialogOpen: true, autoDepositAccountIdForDcRequestMoney: row.original.Id }); }}
																Disabled={row.original.DeleteStatus == 2 ||
																	(row.original.IsRegisteredToDirectDeposit != null
																		&& row.original.IsRegisteredToDirectDeposit == true
																		&& (row.original.DirectDepositReferenceNumber != null
																			|| row.original.DirectDepositReferenceNumber != undefined)
																		&& row.original.IsDirectDepositRegistrationDeletion == true
																	) ? true :
																	row.original.DeleteStatus == 1 ? false :
																	(
																		row.original.DeleteStatus == 3 ||
																		(row.original.DirectDepositReferenceNumber == null
																			&& row.original.DirectDepositRegistrationDate == null
																			&& (row.original.DirectDepositRegistrationStatus == 0 || row.original.DirectDepositRegistrationStatus == null)
																			&& (row.original.IsRegisteredToDirectDeposit == false || row.original.IsRegisteredToDirectDeposit == null)
																			&& (row.original.IsDirectDepositRegistrationDeletion == false || row.original.IsDirectDepositRegistrationDeletion == null)
																		)
																		? true : false
																	)
																}
															/>
												</div>
											)
										},
										{
											Header: "Registration",
											Cell: row=>{
												return (
													<div>{(row.original.ServiceType == "PHONE") ? row.original.Phone: row.original.EMail}
													</div>
												);
													
											}
										},
										{
											Header: "Destination Wallet",
											Cell: row => {
												return (
													<div>{row.original.WalletAccount.AccountName + " - " + row.original.WalletAccount.AccountNumber}
													</div>
												);
											}
										},
										{
											Header: "DCRP",
											Cell: row => {
												return (
													<div>{row.original.IsDcMoneyRequest ? "✔" : ""}
													</div>
												);
											}
										},
										{
											Header: "AD Ref #",
											accessor: "DirectDepositReferenceNumber"
										},
										{
											Header: "Date",
											accessor: "InsertDateTime",
											type: GridColumnType.DateTime
										},
										{
											Header: "Status",
											Cell: row => (
												<div>
													{

														row.original.DeleteStatus == 2 ||
															(row.original.IsRegisteredToDirectDeposit != null
																&& row.original.IsRegisteredToDirectDeposit == true
																&& (row.original.DirectDepositReferenceNumber != null
																	|| row.original.DirectDepositReferenceNumber != undefined)
																&& row.original.IsDirectDepositRegistrationDeletion == true)
															? "Pending" :
															row.original.DeleteStatus == 1 ? "Active" :
																(
																	row.original.DeleteStatus == 3 ||
																		(row.original.DirectDepositReferenceNumber == null
																			&& row.original.DirectDepositRegistrationDate == null
																			&& (row.original.DirectDepositRegistrationStatus == 0 || row.original.DirectDepositRegistrationStatus == null)
																			&& (row.original.IsRegisteredToDirectDeposit == false || row.original.IsRegisteredToDirectDeposit == null)
																			&& (row.original.IsDirectDepositRegistrationDeletion == false || row.original.IsDirectDepositRegistrationDeletion == null))
																		? "Deleted" : "Active")}</div>
											)
										},
									]}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
							</GridItem>
						</GridItem>

					</CardBody>
				</Card>

				<Card className="no-radius">
					<CardHeader>
						<GenericTitle text={"Store List"} />
					</CardHeader>
					<CardBody>
						<GridItem xs={12}>
							<GridItem>
								<GenericGrid
									Data={storeList}
									Columns={[
										{
											Header: "Action",
											accessor: "Action",
											width: 75,
											Cell: row => (
												<GridButton
													Icon={DeleteIcon}
													tooltip="Delete Store Record"
													OnClick={() => { this.handleDeleteStore(row.original.Id); }}
													Disabled={!isBackOffice || row.original.IsClientMainAccount} />
											)
										},
										{
											Header: "Date",
											accessor: "InsertDateTime",
											type: GridColumnType.DateTime
										},
										{
											Header: "Store Name",
											accessor: "Store.StoreName",
											Cell: row => (
												<div>{row.original.IsClientMainAccount == true ? "Main Store" : "SubStore"}</div>
											)
										},
										{
											Header: "E-Mail",
											accessor: "EMail"
										},
										{
											Header: "Store Code",
											accessor: "CustomerInteractId"
										},
										{
											Header: "Store Available Limit",
											accessor: "StoreAvailableLimit",
											ColumnType: GridColumnType.Money,
											type: GridColumnType.Money,
										}
									]}
									ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
							</GridItem>
						</GridItem>
					</CardBody>
				</Card>

			</div >
		);
	}
}

CreateInteracETransferUser.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};

export default withArtifex(CreateInteracETransferUser, {});
