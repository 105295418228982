import { DialogContent, DialogTitle } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DeleteIcon, DetailIcon, EftIcon, ETransferIcon } from "core/Icons";
import RouteHelper from "core/RouteHelper";
import PropTypes from "prop-types";
import React from "react";
import ButtonToolbar from "views/Components/ButtonToolbar.jsx";
import { GenericAlert, GenericCheckInput, GenericDateInput, GenericDialog, GenericDialogActions, GenericExpansionPanel, GenericGrid, GenericNumberInput, GenericSelectInput, GenericTextInput, GenericTitle, GenericLabel, GenericRadioInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, BankingAccountTypes, ClientType, MenuCodes, ProgramCodes, TransactionType, GridColumnType } from "views/Constants/Constant.js";
import ClientHelper from "core/ClientHelper";
import moment from "moment";
import ParameterComponent from "views/Components/ParameterComponent";
import Select from "react-select";

class AllAggregateTransactionGroupReport extends React.Component {
	constructor(props) {
		super(props);

		this.isBackoffice = ClientHelper.IsBackOffice();
		let IsCallCenter = this.props.MenuCode === MenuCodes.ClientAccountActivities;
		let IsSettlement = this.props.MenuCode == MenuCodes.Backoffice_Accounts;

		this.state = {
			checkedAccounts: [],
			customerAccountList: [],
			searchModel: {
				IsRequiredUniqueClientId: true,
				IsRequiredDate: true,
				IsRequiredStatementPeriod: true,
				IsInvalidStatementPeriod: false,
				IsInvalidFromDate: false,
				IsInvalidToDate: false,
				MSTOffset: DateHelper.GetDateTimeMst().format("Z"),
				UseMstTimezone: false
			},
			IsTxnDetailOpen: false,
			TxnDetail: {},
			isLoading: false,
			ClientTypeParameter: {},
			kModel: {}
		};

		this.columns = [

			{
				Header: "Transaction Date",
				accessor: "TransactionDate",
				type: GridColumnType.DateTimeUtc,
				style: this.RenderStyle()
			},
			{
				Header: "Client",
				accessor: "UniqueClientName",
				width: 120,
				Cell: row => (
					<div style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>{row.value ?? " - "}</div>
				),
				show: IsSettlement
			},

			{
				Header: "Channel Name",
				accessor: "ChannelType",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? "600" : "" }}>  {row.value}</p>
				)
			},
			{
				Header: "Received / Sent",
				accessor: "TransactionType",
				Cell: row => (
					<p style={{ fontWeight: row.original.TransactionType == "Received" ? 600 : "normal" }}>{row.value}</p>
				)
			},
			{
				Header: "Amount",
				accessor: "Amount",
				type: GridColumnType.Money,
				style: this.RenderStyle(),
				ColumnType: GridColumnType.Money
			},
			{
				Header: "NoOfTransaction",
				accessor: "NoOfTransaction"
			}

		];

		this.trxTable = React.createRef();

	}

	componentDidMount() {

		if (this.props.MenuCode == MenuCodes.Backoffice_Accounts) {
			this.props.setAppLeftTitle("Settlement Aggregate Transaction Group Report");
			this.GetCustomerBankAccounts(true);
		}
		else {
			this.props.setAppLeftTitle("Aggregate Transaction Group Report");

		}

		if (!this.isBackoffice) {
			this.props.setAppCenterTitle("WALLET");
			this.GetCustomerBankAccounts(true);
			this.GetClientType();
		}
		else {
			this.props.setAppCenterTitle("WALLET");
		}

		this.setState({ loadingCompleted: true });
	}

	ValueChanged = (name, value) => {
		this.setState({ [name]: value });
	}
	TrxFunc = (data) => {

		if (data == null) {
			return "";
		}
		if (data.ParameterValue == TransactionType.Debit) {
			return "Received";
		}
		if (data.ParameterValue == TransactionType.Credit) {
			return "Sent";
		}
		return "";

	}
	RenderAccountTypeParameter = (data) => {
		const parameter = { ...this.state.ClientTypeParameter };
		if (data == null) {
			return "";
		}
		if (data.AccountCode == null || data.AccountCode == "") {
			return data.AccountType;
		}
		if (data.AccountCode != BankingAccountTypes.PrimaryAccount) {
			return data.AccountType;
		}
		if (data.ProgramCode == null || data.ProgramCode == "") {
			return data.AccountType;
		}
		if (data.ProgramCode != ProgramCodes.Bank_Programs_Bank_Account) {
			return data.AccountType;
		}

		if (parameter.ParameterValue == ClientType.Corporate || parameter.ParameterValue == ClientType.SalesAgentCorporate) {
			return "Corporate Wallet";
		} else if (parameter.ParameterValue == ClientType.Personal || parameter.ParameterValue == ClientType.SalesAgentPersonal) {
			return "Individual Wallet";
		} else {
			return data.AccountType;
		}

	}
	GetClientType = () => {
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/BankCustomer/GetClientType",
			{},
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.setState({ ClientTypeParameter: responseData.Item });
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	handleChangeSearchModel = (name, newValue) => {
		this.setState(function (state, props) {
			var model = state.searchModel || {};

			model[name] = newValue;
			if (name == "CheckAllAccounts" && newValue == true) {
				const list = this.state.customerAccountList;
				this.setState({ checkedAccounts: list });
			} else if (name == "CheckAllAccounts" && newValue == false) {
				this.setState({ checkedAccounts: [] });
			}

			if (name == "UniqueClientId" && newValue !== undefined && newValue != null) {
				this.GetCustomerBankAccounts(true);
				this.setState({
					customerAccountList: [], checkedAccounts: []
				});
			}
			else if (name == "UniqueClientId" && (newValue == undefined || newValue == null)) {
				this.setState({
					checkedAccounts: [], customerAccountList: []
				});
			}
			if (name == "Amount") {
				model.MinAmount = newValue;
				model.MaxAmount = newValue;
			}

			if (name == "ReferenceNumber") {

				let hasReferenceNumberSearch = model["ReferenceNumber"]?.length > 0;

				model.IsRequiredStatementPeriod = !hasReferenceNumberSearch;
				model.IsRequiredDate = !hasReferenceNumberSearch;
				model.IsRequiredUniqueClientId = !hasReferenceNumberSearch;
			}

			if (name == "StatementPeriod" || name == "ToDate" || name == "FromDate") {

				if (model.StatementPeriod == undefined || model.StatementPeriod == null || model.StatementPeriod == "") {
					model.StatementControl = false;
					model.IsRequiredStatementPeriod = true;
					model.IsRequiredDate = true;
					this.setState({ searchModel: model });

					if ((model.ToDate == undefined || model.ToDate == "" || model.ToDate == null) && (model.FromDate == undefined || model.FromDate == "" || model.FromDate == null)) {

						model.IsInvalidFromDate = false;
						model.IsInvalidToDate = false;

						model.DateControl = false;
						this.setState({ searchModel: model });
					}
					else {

						model.StatementControl = true;
						model.DateControl = false;
						model.IsRequiredStatementPeriod = false;

					}

				} else {
					model.DateControl = true;
					model.IsInvalidFromDate = false;
					model.IsInvalidToDate = false;
					model.IsRequiredDate = false;
					this.setState({ searchModel: model });
				}
			}

			return { searchModel: model };
		});
	}
	HandleCheckCustomerAccounts = (e, x) => {

		const { checkedAccounts } = this.state;
		var temp = true;

		const items = checkedAccounts.map((item, key) => {
			if (item.Id == x.Id) {
				checkedAccounts.splice(key, 1);
				temp = false;
			}
		}
		);

		if (checkedAccounts.length == 0 && temp) { checkedAccounts.push(x); }
		else if (temp) { checkedAccounts.push(x); }

		this.setState({ checkedAccounts });

	}
	IsValid = () => {
		var temp = { ...this.state.searchModel };
		var kModel = { ...this.state.kModel };
		let IsSettlement = this.props.MenuCode == MenuCodes.Backoffice_Accounts;
		if (!IsSettlement) {

			if (this.isBackoffice) {
				if (temp.IsRequiredUniqueClientId && temp.UniqueClientId == null || temp.UniqueClientId == "") {
					this.props.showMessage("warning", "Please fill required fields!", " Client must be defined");
					kModel.UniqueClientId = true;
					this.setState({ kModel });
					return false;
				} else {
					kModel.UniqueClientId = false;
				}
			}
		}
		this.setState({ kModel });

		if ((temp.IsRequiredStatementPeriod || temp.IsRequiredDate)
			&&
			(temp.StatementPeriod == undefined || temp.StatementPeriod == null || temp.StatementPeriod == "")
			&&
			(temp.FromDate == null || temp.FromDate == undefined || temp.FromDate == "")
			&&
			(temp.ToDate == null || temp.ToDate == undefined || temp.ToDate == "")) {
			this.props.showMessage("warning", "Please fill required fields!", "Please select Statement Period or Date Interval");
			this.setState({ searchModel: temp });
			return false;
		}
		else {
			if ((temp.StatementPeriod != null && temp.StatementPeriod != "")) {
				temp.DateControl = true;
				temp.IsRequiredDate = false;

				this.setState({ searchModel: temp });
			}
			else {
				if (temp.IsRequiredDate && typeof temp.FromDate != "object" && temp.FromDate != "" && temp.FromDate != null) {
					this.props.showMessage("warning", "Please fill required fields!", "From Date is invalid");
					temp.IsInvalidFromDate = true;
					this.setState({ searchModel: temp });
					return false;
				}
				if (temp.IsRequiredDate && typeof temp.ToDate != "object" && temp.ToDate != "" && temp.ToDate != null) {
					this.props.showMessage("warning", "Please fill required fields!", "To Date is invalid");
					temp.IsInvalidToDate = true;
					this.setState({ searchModel: temp });
					return false;
				} else if (temp.IsRequiredDate && temp.ToDate == undefined || temp.ToDate == "") {
					this.props.showMessage("warning", "Please fill required fields!", "To Date must be defined  ");
					temp.IsInvalidToDate = true;
					this.setState({ searchModel: temp });
					return false;

				} else if (temp.IsRequiredDate && temp.FromDate == undefined || temp.FromDate == "") {
					this.props.showMessage("warning", "Please fill required fields!", "From Date must be defined ");
					temp.IsInvalidFromDate = true;
					this.setState({ searchModel: temp });
					return false;

				}
				if (temp.IsRequiredDate && (typeof temp.FromDate == "object" && typeof temp.ToDate == "object") && temp.ToDate < temp.FromDate) {
					this.props.showMessage("warning", "Please fill required fields!", "From Date cannot be bigger than To Date");
					return false;
				}

				if (temp.IsRequiredDate && (typeof temp.FromDate == "object" && typeof temp.ToDate == "object")) {
					var interval = moment.duration(temp.ToDate.diff(temp.FromDate)).asDays();
					if (interval > 30) {
						this.props.showMessage("warning", "Please fill required fields!", "Between two dates cannot be longer than 30 days!");
						return false;
					}
				}
				temp.IsInvalidFromDate = false;
				temp.IsInvalidToDate = false;
				temp.StatementControl = true;
				this.setState({ searchModel: temp });
			}

		}

		let IsCallCenter = this.props.MenuCode == MenuCodes.ClientAccountActivities;
		if (IsCallCenter && (temp.UniqueClientId == null || temp.UniqueClientId == 0)) {
			this.ShowMessage("error", "Error", "Client must be selected for search operation !");
			return false;
		}

		return true;
	}

	GetCustomerBankAccounts = (status) => {
		const temp = { ...this.state.searchModel };
		let IsSettlement = this.props.MenuCode == MenuCodes.Backoffice_Accounts;

		if (IsSettlement) temp.SettlementAccountStatus = true;
		else temp.SettlementAccountStatus = false;

		if (IsSettlement) {
			if (status == undefined) {
				var isvalidControl = this.IsValid();
				if (isvalidControl == false) return;
				this.GetAccountList(temp);
			}
			this.GetAccountList(temp);
		} else if (this.isBackoffice) {
			if ((temp.UniqueClientId == null || temp.UniqueClientId == "")) return;
			else if (status) {
				this.GetAccountList(temp);
				return;
			} else if (status == undefined && !this.IsValid()) return;

			this.GetAccountList(temp);

		}
		else {
			if (status) {
				this.GetAccountList(temp);
				return;
			} else {
				var isvalidControl1 = this.IsValid();
				if (isvalidControl1 == false) return;

				this.GetAccountList(temp);
			}
		}

	}
	GetAccountList = (temp) => {
		this.handleChangeSearchModel("UseMstTimezone", false);
		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/Account/ClientAccountActivites",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error !", responseData.ErrorDescription);
					return;
				} else {
					this.setState({ customerAccountList: responseData.Item || [] });
				}
				this.setState({ isLoading: false });

			},
			error => {
				this.ShowMessage("error", "Error", error);
				this.setState({ isLoading: false });

			}
		);
	}

	SendToWorkflow = id => {
		var data = { Id: id };
		var jsonData = JSON.stringify(data);

		var requestData = {
			MenuCode: MenuCodes.CloseAccount,
			ActionCode: ActionCodes.Submit,
			JsonData: jsonData,
			RowId: id
		};

		this.setState({ isLoading: true });
		Proxy.POST("/coreapi/v1.0/WorkflowData/InsertWorkflowData",
			requestData,
			responseData => {
				this.setState({ isLoading: false });

				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Request successfully sent for approval");
			},
			errorMessage => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", "An error occurred while sending approve request " + errorMessage);
			}
		);
	}


	ShowMessage = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	ShowConfirmMessage = (type, title, message, onConfirm) => {
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={onConfirm} />
		});
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	ClearData = () => {

		if (this.isBackoffice && this.props.MenuCode != MenuCodes.Backoffice_Accounts) //Settlement Acc Acvt
		{
			this.setState({
				customerAccountList: [], alert: null, isLoading: false, checkedAccounts: [], searchModel: {
					IsRequiredDate: true,
					IsRequiredStatementPeriod: true,

				}, IsTxnDetailOpen: false, TxnDetail: {},
			});
		}
		this.setState({
			alert: null, isLoading: false, checkedAccounts: [], searchModel: {
				IsRequiredDate: true,
				IsRequiredStatementPeriod: true,
				IsRequiredUniqueClientId: true,
			}, IsTxnDetailOpen: false, TxnDetail: {},
		});
	}
	handleChangeFilterPanel = panel => (event, expanded) => {
		this.setState({
			active: expanded ? panel : -1
		});
	};
	RenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["+", ""]
		};
	}
	BalanceRenderStyle = () => {
		return {
			column: "TransactionType",
			condition: "Received",
			style: { fontWeight: "600" },
			prefix: ["", ""]
		};
	}


	HandleGetList = () => {
		this.setState(state => ({ trxGridToken: !state.trxGridToken }));
	}

	HandleGetListMst = () => {
		this.handleChangeSearchModel("UseMstTimezone", true);
		this.HandleGetList();
	}

	ExportClick = (timezone) => {
		var trxColumns = [];
		this.trxTable.current.props.Columns.forEach(c => {
			if (c.accessor != "Actions" && c.accessor != "ED" && (c.show == undefined || c.show)) {
				trxColumns.push({ value: c.accessor, title: c.Header, columntype: c.ColumnType, type: c.type });
			}
		}, this);
		this.handleChangeSearchModel("UseMstTimezone", timezone === "MST");
		this.setState({ isDownloadDialogOpen: true, GridKeyValueList: trxColumns, ExportSelectedColumns: trxColumns });
	}

	ExcelDownload = () => {
		if (!this.state.ExportReportType) {
			this.ShowMessage("warning", "Export Report Type not selected", "Select Export Report Type to continue");
			return;
		}

		if (this.state.ExportReportType == "PDF" && this.state.ExportSelectedColumns.length > 15) {
			this.ShowMessage("warning", "PDF document cannot exceed 15 columns", "PDF document cannot exceed 15 columns");
			return;
		}

		this.setState({ isDownloadDialogOpen: false });

		if (!this.IsValid())
			return;

		const temp = { ...this.state.searchModel };

		let IsSettlement = this.props.MenuCode == MenuCodes.Backoffice_Accounts;
		if (IsSettlement)
			temp.SettlementAccountStatus = true;
		else
			temp.SettlementAccountStatus = false;

		var ids = [];
		this.ShowConfirmMessage("question", "Long Process Warning", "This process may take a while. Continue ?", () => {
			this.hideAlert();
			this.state.checkedAccounts.forEach(element => {
				ids.push(element.Id);
				if (element.SubAccountList != null) {
					element.SubAccountList.forEach(x => {
						ids.push(x);
					});
				}
			});
			temp.AccountIds = ids;
			var jsonColList = [];

			var trxColumns = {};
			this.state.ExportSelectedColumns.map(c => {
				trxColumns[c.value] = { value: c.value, title: c.title, columntype: c.columntype, type: c.type };
			});
			jsonColList.push(trxColumns);

			var mytitle = "";
			if (IsSettlement) { mytitle = "Settlement Wallet Activities Report"; }
			else { mytitle = "Client Wallet Activities Report"; }

			temp.jsonFile = {
				colmns: jsonColList,
				format: this.state.ExportReportType,
				title: mytitle
			};

			this.setState({ isLoading: true });
			Proxy.DownloadGeneratedFile(
				"/bankapi/v1.0/Transaction/SearchAllAggregateTransactionGroupExport",
				temp,
				responseData => {
					this.setState({ isLoading: false });
					if (!responseData.IsSucceeded) {
						this.ShowMessage("error", "Error", responseData.ErrorDescription);
						return;
					}
				},
				errorMessage => {
					this.setState({ isLoading: false });
					this.ShowMessage("error", "Error", errorMessage);
				},
				this.props.showMessage
			);
		});

	}



	render() {
		const { Disabled } = this.props;
		const { customerAccountList, checkedAccounts, searchModel, TxnDetail, IsTxnDetailOpen, alert, SettlementAccountStatus, kModel } = this.state;
		let IsCallCenter = this.props.MenuCode === MenuCodes.ClientAccountActivities;
		let IsSettlement = this.props.MenuCode == MenuCodes.Backoffice_Accounts;
		var endDate = (searchModel.FromDate instanceof moment) ? searchModel.FromDate.clone() : "";

		var GridDataAccounts = customerAccountList == null ? [] : customerAccountList.map(d => {
			return {
			 
				AP: (
					<div>
						<GenericCheckInput
							Value={checkedAccounts.filter(c => c.Id == d.Id).length > 0}
							ValueChanged={(value) => {
								this.HandleCheckCustomerAccounts(value, d);
							}}
						/>
					</div>
				),
				Id: d.Id,
				ProgramId: d.ProgramId,
				CustomerBankAccountId: d.CustomerBankAccountId,
				AccountShortName: d.AccountShortName,
				BankAccountId: d.BankAccountId,
				UniqueClientId: d.UniqueClientId,
				Balance: d.Balance,
				AccountName: d.AccountName,
				PendingBalance: d.PendingBalance,
				AccountNumber: d.AccountNumber,
				AccountNumberStr: d.AccountNumberStr,
				BranchNumber: d.BranchNumber,
				BankNumber: d.BankNumber,
				AccountTypeId: d.AccountTypeId,
				AccountType: d.AccountType,
				AccountType4: d.AccountType4,
				AccountType6: d.AccountType6,
				AccountCode: d.AccountCode,
				ProgramCode: d.ProgramCode,
				ProgramName: d.ProgramName,
				CurrencyCode: d.CurrencyCode,
				CurrencyDesc: d.CurrencyDesc,
				CurrencySymbol: d.CurrencySymbol,
				AmountDisplay: d.AmountDisplay,
				IsChequingAccount: d.IsChequingAccount,
				SubAccountList: d.SubAccountList
			};
		});
		return (
			<div>
				<LoadingComponent Show={this.state.isLoading} />
				{alert}
				<GridContainer spacing={16}>
					<GridItem xs={12}>
						<ButtonToolbar ButtonList={[
							{ Code: "Clear", OnClick: () => this.ClearData(), Disabled: Disabled },
							{ Code: "Search", OnClick: () => { this.HandleGetList(); this.GetCustomerBankAccounts(); }, Disabled: Disabled },
							{ Code: "Export", OnClick: () => this.ExportClick("UTC"), Disabled: Disabled },
							{ Code: "SearchStatement", OnClick: () => this.HandleGetListMst(), Disabled: Disabled },
							{ Code: "GetStatement", OnClick: () => this.ExportClick("MST"), Disabled: Disabled },
							//{ Code: "SearchStatement", OnClick: () => { this.HandleGetList(); this.GetCustomerBankAccounts(); }, Disabled: Disabled },
						]} menuId={this.props.menuId} ApprovalData={this.props.ApprovalData} After={this.props.After} />
						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={" Main Parameters "} />
							</CardHeader>
							<b><p style={{ paddingTop: "20px", marginLeft: "10px" }}></p></b>
							<CardBody>
								<GridContainer spacing={16}>
									<GridItem xs={12}>
										<GridContainer>
											{(IsCallCenter || this.isBackoffice) && (!IsSettlement) &&

												<GridItem xs={3}>
													<GenericSelectInput
														Name="UniqueClientId"
														LabelMd={4}
														LabelText="Client"
														Method="POST"
														Url="/bankapi/v1.0/BankCustomer/Search"
														Parameter={{}}
														DataRoot="Item"
														KeyValueMember="Id"
														RenderItem={d => `${d.UniqueClientId} - ${d.Name}`}
														Value={searchModel.UniqueClientId || ""}
														ValueChanged={this.handleChangeSearchModel}
														CanClear
														Required={searchModel.IsRequiredUniqueClientId}
														IsInvalid={kModel.UniqueClientId}

													/>
												</GridItem>
											}

											<GridItem xs={3}>
												<ParameterComponent
													Name="StatementPeriod"
													LabelText="Statement Period"
													Value={searchModel.StatementPeriod}
													ValueChanged={this.handleChangeSearchModel}
													KeyValueMember="ParameterValue2"
													ParameterCode="StatementPeriod"
													ParameterValue2Excludes={["31"]}
													Required={searchModel.IsRequiredStatementPeriod}
													IsInvalid={searchModel.IsInvalidStatementPeriod}
													Disabled={searchModel.StatementControl}
													Sorted={{ Member: "ParameterValue3" }}
												/>

											</GridItem>
											<GridItem xs={3}>
												<GenericSelectInput
													Name="ChannelType"
													LabelText="Channel Name"
													Value={searchModel.ChannelType || ""}
													ValueChanged={this.handleChangeSearchModel}
													KeyValueMember="ParameterValue"
													TextValueMember="ParameterDesc"
													Method="POST"
													Url="/coreapi/v1.0/Parameter/Search"
													Parameter={{ ParameterCode: "ChannelNameType" }}
													DataRoot="Item"
													Sorted={{ Member: "ParameterDesc" }}
												/>
											</GridItem>

										</GridContainer>

										<GenericExpansionPanel IsActive={true} Title="Advance Filter" AlignToRight>
											<GridContainer>
												<GridItem xs={4}>
													<GenericSelectInput
														Name="DebitCreditId" All LabelText="Received/Sent" Value={searchModel.DebitCreditId || ""} ValueChanged={this.handleChangeSearchModel}
														KeyValueMember="Id" TextValueMember="ParameterDesc"
														RenderItem={this.TrxFunc}
														Method="GET" Url="/bankapi/v1.0/Transaction/GetAllFinancialTransactionType"
														DataRoot="Item" />
													</GridItem>
												<GridItem xs={4}>
													<GenericDateInput
														Name="FromDate"
														LabelText="From Date"
														LabelMd={4}
														IsFuture={false}
														MaxDate={DateHelper.GetDate()}
														Value={searchModel.FromDate == null ? "" : DateHelper.ToDateInputValueAsUtc(searchModel.FromDate) || undefined}
														ValueChanged={this.handleChangeSearchModel}
														Utc
														Disabled={searchModel.DateControl}
														Required={searchModel.IsRequiredDate}
														IsInvalid={searchModel.IsInvalidFromDate}
													/>
												</GridItem>
												<GridItem xs={4}>
													<GenericDateInput
														Name="ToDate"
														LabelText="To Date"
														LabelMd={4}
														IsFuture={false}
														MinDate={searchModel.FromDate}
														MaxDate={endDate != "" ? endDate.add(30, "days") : undefined}
														Value={searchModel.ToDate == null ? "" : DateHelper.ToDateInputValueAsUtc(searchModel.ToDate) || ""}
														ValueChanged={this.handleChangeSearchModel}
														Utc
														Required={searchModel.IsRequiredDate}
														IsInvalid={searchModel.IsInvalidToDate}
														Disabled={searchModel.DateControl}
													/>
												</GridItem>
											</GridContainer>
										</GenericExpansionPanel>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Wallets"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											Data={GridDataAccounts}
											HideButton={true}
											Columns={[
												{
													width: 100,
													Header: IsCallCenter == true ? "Deactive" : "Actions",
													accessor: "ED",
													sortable: false,
													filterable: false,
													show: this.isBackoffice == true ? false : true
												},
												{
													Header: "Select",
													accessor: "AP",
													sortable: false,
													filterable: false,
													width: 60,
													Footer: (
														<GenericCheckInput Grid={true} Name="CheckAllAccounts" LabelText="" Value={searchModel.CheckAllAccounts || false} ValueChanged={this.handleChangeSearchModel} />
													)
												},
												{
													Header: "Wallet Name",
													accessor: "AccountName",
													Footer: (
														<span>
															<strong>All Wallets</strong>{" "}
														</span>
													)
												},
												{
													Header: "Wallet Type",
													accessor: "AccountType",
													Cell: row => (
														<div>{this.RenderAccountTypeParameter(row.original)} </div>
													),
													Footer: (
														<span>
															<strong>Total{"    "} {customerAccountList == null ? 0 : customerAccountList.length}</strong>{" "}
														</span>
													)
												},
												{
													Header: "Program Name",
													accessor: "ProgramName",
													show: !IsSettlement

												},
												{
													Header: "Wallet Number",
													accessor: "AccountNumber"
												},
												{
													Header: "Chequing Wallet",
													accessor: "IsChequingAccount",
													show: (IsSettlement != true && this.isBackoffice == true)
												},
												{
													Header: "Currency",
													accessor: "CurrencyCode"
												},
												{
													Header: "Balance",
													accessor: "Balance",
													Cell: row => (
														<div>{Formatter.FormatMoneySpecial(row.original.CurrencySymbol, row.value)}</div>
													),
													// Footer: (
													// 	<span>
													// 		<strong>
													// 			{Formatter.FormatMoney(customerAccountList.TotalAccountBalance)}
													// 		</strong>
													// 	</span>
													// )
												},
												{
													Header: "Pending Balance",
													accessor: "PendingBalance",
													Cell: row => (
														<div>{Formatter.FormatMoneySpecial(row.original.CurrencySymbol, row.value)}</div>
													),
													show: !IsSettlement
													// Footer: (
													// 	<span>
													// 		<strong>{Formatter.FormatMoney(customerAccountList.TotalAccountBalance)}</strong>
													// 	</span>
													// )
												}
											]}
											ProgramCode={ProgramCodes.Bank_Programs_Bank_Account} />
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>

						<Card className="no-radius">
							<CardHeader>
								<GenericTitle text={"Transaction List"} />
							</CardHeader>
							<CardBody>
								<GridItem xs={12}>
									<GridItem>
										<GenericGrid
											key={"trxGrid" + this.state.trxGridToken}
											ref={this.trxTable}
											IsSorted={false}
											ShowPagination={true}
											PageSize={10}
											Columns={this.columns}
											ProgramCode={ProgramCodes.Bank_Programs_Bank_Account}
											ServerSide
											LoadAtStartup={this.state.loadingCompleted}
											ValidateRequest={this.IsValid}
											PrepareRequest={() => {
												const temp = { ...this.state.searchModel };
												var ids = [];
												this.state.checkedAccounts.forEach(element => {
													ids.push(element.Id);
													if (element.SubAccountList != null) {
														element.SubAccountList.forEach(x => {
															ids.push(x);
														});
													}
												});
												temp.AccountIds = ids;

												let IsSettlement = this.props.MenuCode == MenuCodes.Backoffice_Accounts;
												if (IsSettlement) temp.SettlementAccountStatus = true;
												else temp.SettlementAccountStatus = false;

												return temp;
											}}
											RequestUrl="/bankapi/v1.0/Transaction/SearchAllAggregateTransactionGroupPaged"
											RequestMethod="POST"
										/>
									</GridItem>
								</GridItem>
							</CardBody>
						</Card>
					</GridItem>
				</GridContainer>


				<GenericDialog open={this.state.isDownloadDialogOpen} fullWidth={true}>
					<DialogTitle>
						<GenericLabel Text="Select Report Type" FontSize="20px" Bold={true} />
					</DialogTitle>
					<DialogContent style={{ height: 300 }}>
						<GenericRadioInput
							Name="ExportReportType"
							LabelText="Report Type"
							IsStatic={true}
							StaticData={[{ Value: "PDF", Text: "PDF" }, { Value: "CSV", Text: "CSV" }, { Value: "EXCEL", Text: "EXCEL" }]}
							KeyValueMember="Value"
							TextValueMember="Text"
							Value={this.state.ExportReportType}
							ValueChanged={this.ValueChanged}
						/>
						<GenericLabel Text="Select Columns" FontSize="20px" Bold={true} />
						<GridItem style={{ marginTop: 20 }}>
							<Select
								value={this.state.ExportSelectedColumns}
								onChange={value => this.setState({ ExportSelectedColumns: value })}
								isMulti
								name="columns"
								getOptionLabel={d => d.title}
								options={this.state.GridKeyValueList}
								className="basic-multi-select"
								classNamePrefix="select"
							/>
						</GridItem>
					</DialogContent>
					<GenericDialogActions>
						<Button size="sm" onClick={this.ExcelDownload}>Download</Button>
						<Button size="sm" onClick={() => this.setState({ isDownloadDialogOpen: false })}>Cancel</Button>
					</GenericDialogActions>
				</GenericDialog>
			</div>
		);
	}
}

AllAggregateTransactionGroupReport.propTypes = {
	classes: PropTypes.object,
	setAppLeftTitle: PropTypes.func,
	setAppCenterTitle: PropTypes.func
};


export default AllAggregateTransactionGroupReport;