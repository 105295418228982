import { FormControl, InputLabel } from "@material-ui/core";
import genericSelectInputStyle from "assets/jss/generic/genericSelectInputStyle.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy } from "core";
import ResourceHelper from "core/ResourceHelper";
import withArtifex from "core/withArtifex";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Select from "react-select";
import GenericVirtualizedMenuList from "./GenericSelectInput/GenericVirtualizedMenuList";

const groupStyles = {
	display: "flex",
	alignItems: "center",
	justifyContent: "space-between",
};

const customSelectComponents = { MenuList: GenericVirtualizedMenuList };
class GenericSelectInput extends PureComponent {

	constructor(props) {
		super(props);

		this.state = {
			Data: [],
			MappedData: [],
			isLoading: false,
		};

		this.isCancelled = false;

		this.FetchDataCallback = this.FetchDataCallback.bind(this);
		this.ErrorCallback = this.ErrorCallback.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.isFunction = this.isFunction.bind(this);
		this.isObject = this.isObject.bind(this);
		this.FilterData = this.FilterData.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.selectProps = {};
		this.dataContainer = {};

		this.placeHolderText = "Select One";
	}

	handleChange(selectedOption) {
		const { Name, KeyValueMember, ValueChanged, StaticData } = this.props;
		const { Data } = this.state;
		const data = StaticData || Data;

		this.setState({ selectedOption: selectedOption });

		if (ValueChanged) {
			var newValue = selectedOption != null ? selectedOption.value : null;
			ValueChanged(Name, newValue, data.find(x => x[KeyValueMember] == newValue));
		}
	}

	handleInputChange(selectedOption) {
		const { Name, InputValueChanged } = this.props;
		if (InputValueChanged) {
			InputValueChanged(Name, selectedOption);
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.FilterPredicate && this.props.FilterPredicate !== prevProps.FilterPredicate && this.state.Data && this.state.Data.length > 0) {
			var mappedData = this.Map(this.state.Data);
			this.setState({ MappedData: mappedData, isLoading: false });
		}
	}

	componentWillUnmount() {
		this.isCancelled = true;
	}

	FilterData(data, predicates) {
		if (this.props.FilterPredicate) {
			var result = data.filter((value) => {
				return predicates(value);
			});
			return result;
		}
		return data;
	}

	componentDidMount() {
		const { Name, Method, Url, Parameter, KeyValueMember, IsStatic, DefaultIndex, StaticData, ValueChanged, TaskPoolDomain } = this.props;

		if (IsStatic) {
			var mappedData = this.Map(StaticData);
			this.setState({ MappedData: mappedData });

			if (DefaultIndex != null && DefaultIndex >= 0 && StaticData.length > DefaultIndex) {
				ValueChanged(Name, StaticData[DefaultIndex][KeyValueMember]);
			}

			return;
		}

		this.setState({ isLoading: true });

		if (Method == "GET") {
			if (TaskPoolDomain && this.isObject(TaskPoolDomain))
				TaskPoolDomain.AppendTask(Proxy.GET(Url, this.FetchDataCallback, this.ErrorCallback));
			else
				Proxy.GET(Url, this.FetchDataCallback, this.ErrorCallback);
		}
		else if (Method == "POST") {
			if (TaskPoolDomain && this.isObject(TaskPoolDomain))
				TaskPoolDomain.AppendTask(Proxy.POST(Url, Parameter, this.FetchDataCallback, this.ErrorCallback));
			else
				Proxy.POST(Url, Parameter, this.FetchDataCallback, this.ErrorCallback);
		}
		else {
			this.ErrorCallback();
		}
	}

	isFunction(obj) {
		return typeof obj == "function";
	}

	isObject(obj) {
		return obj !== null && typeof obj === "object";
	}

	FetchDataCallback(response) {
		if (this.isCancelled) return;

		if(response.Item != null){	
			response.Item.forEach( item => {
				if(item.hasOwnProperty("ParameterDesc")){
					item.ParameterDesc = ResourceHelper.CorrectAccountNames(item.ParameterDesc);
				}
				if(item.hasOwnProperty("Description")){
					item.Description = ResourceHelper.CorrectAccountNames(item.Description);
				}
			})
		}
		
		const { DataRoot, Name, DefaultIndex, KeyValueMember, Sorted, ValueChanged, AfterFetchCallback } = this.props;

		var data = response[DataRoot] || [];

		if (AfterFetchCallback)
			AfterFetchCallback(data);

		if (Sorted) {
			data.sort((a, b) => {
				var nameA = a[Sorted.Member];
				if (nameA && nameA.toUpperCase) nameA = nameA.toUpperCase();
				var nameB = b[Sorted.Member];
				if (nameB && nameB.toUpperCase) nameB = nameB.toUpperCase();

				if (nameA < nameB) {
					return Sorted.Desc ? 1 : -1;
				}
				if (nameA > nameB) {
					return Sorted.Desc ? -1 : 1;
				}
				return 0;
			});
		}

		var mappedData = this.Map(data);
		this.setState({ Data: data, MappedData: mappedData, isLoading: false });

		if (DefaultIndex != null && DefaultIndex >= 0 && data.length > DefaultIndex) {
			ValueChanged(Name, data[DefaultIndex][KeyValueMember], data[DefaultIndex]);
		}
	}

	ErrorCallback() {
		if (this.isCancelled) return;
		this.setState({ isLoading: false });
	}

	Map(mainData) {
		if (mainData == null || mainData.length == 0)
			return [];

		var data = [...mainData];

		const { KeyValueMember, TextValueMember, RenderItem, All, MultiLanguage, FilterPredicate } = this.props;
		if (FilterPredicate && this.isFunction(FilterPredicate))
			data = this.FilterData(data, FilterPredicate);

		var returnData = data.map(d => {
			var label = d[TextValueMember];
			if (RenderItem)
				label = RenderItem(d);
			else if (MultiLanguage)
				label = ResourceHelper.Get(label);

			return { value: d[KeyValueMember], label: label };
		});

		if (All == true) {
			returnData.unshift({ value: -1, label: "All" });
		}

		return returnData;
	}

	GetStyles() {
		const { Required, IsInvalid } = this.props;

		var borderColor = "#e9eaec";
		if (IsInvalid) {
			borderColor = window.InvalidFieldColor;
		}
		else if (Required) {
			borderColor = window.RequiredFieldColor;
		}

		return {
			control: (base) => (
				{
					...base,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					border: `1px solid ${borderColor}`,
					borderRadius: "unset",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					},
					fontWeight: "400"
				}),
			valueContainer: (base) => (
				{
					...base,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						background: "unset",
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important",
						padding: "unset"
					},
					padding: "unset"
				}),
			indicatorsContainer: (base) => (
				{
					...base,
					height: "25px",
					minHeight: "25px",
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					}
				}),
			option: (base) => (
				{
					...base,
					fontSize: "12px",
					fontWeight: "400",
					"&:active": {
						border: `1px solid ${borderColor} !important`,
						boxShadow: "unset !important"
					}
				}),
			container: (base) => ({
				...base
			}),
			menu: (base) => ({
				...base,
				zIndex: "50"
			}),
			singleValue: (base, state) => {
				var isPlaceholder = this.placeHolderText == state.data.label;
				var styleObj = {
					...base
				};

				if (!isPlaceholder) {
					styleObj.color = "rgba(0, 0, 0, 1)";
				}
				return styleObj;
			}
		};
	}
	render() {
		const { classes, LabelText, Name, Value, KeyValueMember, TextValueMember, Disabled,
			StaticData, LabelMd, TitleLabel, LabelStyle, SelectStyle, RenderItem, IsTextBold, CanClear, VirtualizedList } = this.props;
		const { Data, MappedData } = this.state;

		const data = StaticData || Data;

		var titleLabel = TitleLabel ? TitleLabel : this.placeHolderText;
		var tempSelectedOption = {};

		if (Value != null) {
			if (Value == -1) {
				tempSelectedOption.value = -1;
				tempSelectedOption.label = "All";
			} else {
				var label = data.find(x => x[KeyValueMember] == Value);
				if (label == undefined) {
					tempSelectedOption.value = -2;
					tempSelectedOption.label = titleLabel;
				} else {
					tempSelectedOption.value = Value;
					tempSelectedOption.label = RenderItem ? RenderItem(label) : label[TextValueMember];
				}
			}
		}

		var lastData = MappedData == null || MappedData.length == 0
			? this.Map(data)
			: MappedData;

		const formatGroupLabel = () => (
			<div style={groupStyles}>
				<span>{titleLabel}</span>
			</div>
		);
		this.dataContainer.groupedItem = this.dataContainer.groupedItem || {};
		this.dataContainer.groupedOptions = this.dataContainer.groupedOptions || [this.dataContainer.groupedItem];
		Object.assign(this.dataContainer.groupedItem, {
			label: titleLabel,
			options: lastData,
		});
		const groupedOptions = this.dataContainer.groupedOptions;
		var labelMd = LabelMd == -1 ? undefined : LabelMd;
		var selectMd = LabelMd == -1 ? undefined : 12 - LabelMd;

		if (labelMd == 0) labelMd = undefined;
		if (selectMd == 0) selectMd = undefined;

		this.selectProps.components = (VirtualizedList !== false && VirtualizedList !== undefined) ? customSelectComponents : undefined;
		return (
			<FormControl fullWidth className={classes.selectFormControl}>
				<GridContainer
					container
					direction="row"
					justify="flex-start"
					alignItems="flex-end"
					style={{ marginTop: 6 }}>

					{LabelText != undefined && LabelText != "" &&
						<GridItem xs={labelMd} style={LabelStyle}>
							<InputLabel htmlFor={Name} className={IsTextBold != null ? classes.selectLabelBold : classes.selectLabel} >
								{LabelText}
							</InputLabel>
						</GridItem>
					}

					<GridItem xs={selectMd} style={SelectStyle}>
						<div>
							<Select
								onInputChange={this.handleInputChange}
								formatGroupLabel={formatGroupLabel}
								placeholder={titleLabel}
								isDisabled={Disabled}
								styles={this.GetStyles()}
								value={Value != null ? tempSelectedOption : null}
								onChange={this.handleChange}
								options={lastData != null && lastData.length > 0 ? groupedOptions : lastData}
								isClearable={CanClear}
								theme={(theme) => ({
									...theme,
									borderRadius: 0,
									colors: {
										...theme.colors,
										primary: "black",
										primary25: "rgb(246, 246, 246)",
										primary50: "rgb(246, 246, 246)",
										primary75: "rgb(246, 246, 246)"
									},
								})}
								{...this.selectProps}
							/>
						</div>
					</GridItem>
				</GridContainer>
			</FormControl>
		);
	}
}

GenericSelectInput.propTypes = {
	classes: PropTypes.object.isRequired,
	ValueChanged: PropTypes.any,
	InputValueChanged: PropTypes.any,
	Value: PropTypes.any,
	Name: PropTypes.string,
	LabelText: PropTypes.string,
	KeyValueMember: PropTypes.string,
	TextValueMember: PropTypes.string,
	Method: PropTypes.string,
	Url: PropTypes.string,
	Parameter: PropTypes.any,
	DataRoot: PropTypes.string,
	IsStatic: PropTypes.bool,
	StaticData: PropTypes.array,
	Disabled: PropTypes.bool,
	DefaultIndex: PropTypes.number,
	Sorted: PropTypes.object,
	AfterFetchCallback: PropTypes.any,
	RenderItem: PropTypes.func,
	All: PropTypes.bool,
	LabelMd: PropTypes.number,
	TitleLabel: PropTypes.string,
	LabelStyle: PropTypes.object,
	SelectStyle: PropTypes.object,
	MultiLanguage: PropTypes.bool,
	IsTextBold: PropTypes.bool,
	CanClear: PropTypes.bool,
	FilterPredicate: PropTypes.func,
	TaskPoolDomain: PropTypes.any,
	Required: PropTypes.bool,
	IsInvalid: PropTypes.bool,
  /** Create VirtualizedList for regular list. Improves scroll speed significantly on large list  */
	VirtualizedList: PropTypes.bool
};

GenericSelectInput.defaultProps = {
	LabelMd: 4,
	CanClear: true
};

export default withArtifex(GenericSelectInput, genericSelectInputStyle);