import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { Proxy, withArtifex } from "core";
import DateHelper from "core/DateHelper";
import Formatter from "core/Formatter";
import { DeleteIcon, EditIcon, ProfileIcon } from "core/Icons";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import CanadaPostAddressComponent from "views/Components/CanadaPostAddressComponent";
import { GenericAlert, GenericDateInput, GenericGrid, GenericLabel, GenericNumberInput, GenericSelectInput, GenericTextInput } from "views/Components/Generic";
import GridButton from "views/Components/GridButton.jsx";
import LoadingComponent from "views/Components/LoadingComponent";
import { ActionCodes, IdentificationMethod, MenuCodes, ClientType, GridColumnType } from "views/Constants/Constant";


class CorporateAuthOwner extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			vModel2: {},
			vModel: {},
			isZipCodeOptional: false,
			corporateAuthOwnerModel: {},
			isLoading: false,
			alert: null,
			CustomDisabled: null,
		};
		


		this.ParameterClientTypeC = { ParameterCode: "ClientType", ParameterValue2: "C" };
		this.ParameterMaxLength20 = { maxLength: 20 };
		this.ParameterMaxLength30 = { maxLength: 30 };
		this.ParameterMaxLength200 = { maxLength: 200 };
		this.ParameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.ParameterIdentityVerifyingPerson = { ParameterCode: "IdentityVerifyingPerson" };
		this.ParameterIdentityType = { ParameterCode: "IdentityType" };
		this.ParameterIdentificationMethod = { ParameterCode: "IdentificationMethod" };
		this.sortedParameterDesc = { Member: "ParameterDesc" };
		this.CloumnsData = [
			{
				Header: "Actions",
				accessor: "Actions",
				Cell: row => (
					<div>
						<GridButton
							tooltip="Edit"
							Icon={EditIcon}
							Disabled={this.state.CustomDisabled || props.Disabled}
							OnClick={() => this.setState({ corporateAuthOwnerModel: { ...row.original } })} />

						<GridButton
							tooltip="Delete"
							Icon={this.state.CustomDisabled || DeleteIcon}
							Disabled={props.Disabled}
							OnClick={() => this.showQuestionMessage("Delete this record", row.original)} />
					</div>
				),
				sortable: false,
				width: 100
			},
			{
				Header: "Relationship",
				accessor: "Id",
				sortable: false,
				Cell: row => (
					this.TreeDisplay(row.original)
				),width: 200
			},
			{
				Header: "Id",
				accessor: "Id",
				sortable: false,
			},
			{
				Header: "Business Name",
				accessor: "NameOfBusiness",
				sortable: false
			},
			{
				Header: "First Name",
				accessor: "FirstName",
				sortable: false
			},
			{
				Header: "Middle Name",
				accessor: "MiddleName",
				sortable: false
			},
			{
				Header: "Last Name",
				accessor: "LastName",
				sortable: false
			},
			{
				Header: "% of Ownership",
				accessor: "OwnerShipPercentage",
				sortable: false
			},
			{
				Header: "Address Line 1",
				accessor: "Address1",
				sortable: false
			},
			{
				Header: "Country",
				accessor: "Country.Name",
				sortable: false
			},
			{
				Header: "Province",
				accessor: "Province.Name",
				sortable: false
			},
			{
				Header: "City",
				accessor: "City.Name",
				sortable: false
			},
			{
				Header: "Postal Code",
				accessor: "ZipCode",
				sortable: false
			},
			{
				Header: "Verifying Person 1",
				accessor: "IdentityVerifyingPerson1.ParameterDesc",
				sortable: false
			},
			{
				Header: "Identification Method 1",
				accessor: "IdentificationMethod1.ParameterDesc",
				sortable: false
			},
			{
				Header: "Identification Type 1",
				accessor: "IdentityType1.ParameterDesc",
				sortable: false
			},
			{
				Header: "Identification No. 1",
				accessor: "IdentityNumber1",
				Cell: row => (
					props.paramGlobalModel.IsExceptClientSetup == true ? this.FilterIdentityNumber(row.value) : row.value
				),
				sortable: false
			},
			{
				Header: "ID Place of Issue 1",
				accessor: "IdentityPlaceOfIssue1",
				sortable: false
			},
			{
				Header: "Identity Expire Date 1",
				accessor: "IdentityExpireDate1",
				type: GridColumnType.DateUtc,
				sortable: false
			}
		]
	}

	componentDidMount() {

		const global = { ...this.props.paramGlobalModel };
		if (global == null || global.Id == 0) {
			console.log("You must select a client or create new one to add corporate auth owner.");
		}
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ corporateAuthOwnerModel: model });

		this.props.FetchClientLimitedAuthority(MenuCodes.CSLimitedBeneficiaryOwner, ActionCodes.Submit, (res) => this.setState({ CustomDisabled: res }));
	}

	OperationCheck = () => {
		var ErrorList = [];
		const { vModel, vModel2 } = this.state;
		const global = { ...this.props.paramGlobalModel };
		const model = this.state.corporateAuthOwnerModel;
		if (global.Id == null || global.Id == 0) {
			ErrorList.push("You must select a client or create new one to add.");
		}
		if (model == null) {
			return;
		}

		if (model.OwnerShipPercentage == null || model.OwnerShipPercentage == "") {
			vModel.OwnerShipPercentage = true;
			ErrorList.push("OwnerShip Percentage must be defined !.");
		} else { vModel.OwnerShipPercentage = false; }

		if (model.OwnerShipPercentage != null && model.OwnerShipPercentage > 100) {
			ErrorList.push("OwnerShip Percentage must be smaller or equal to 100 !.");
		}

		if (model.AuthOwnerTypeId == null || model.AuthOwnerTypeId == "") {
			vModel.AuthOwnerTypeId = true;
			ErrorList.push("OwnerShip Type must be defined !");
		} else { vModel.AuthOwnerTypeId = false; }

		if (model.Address1 == null || model.Address1 == "") {
			vModel.Address1 = true;
			ErrorList.push("Address1 must be defined !.");
		} else { vModel.Address1 = false; }

		if (model.CountryId == null || model.CountryId == "") {
			vModel.CountryId = true;
			ErrorList.push("Country must be defined !.");
		} else { vModel.CountryId = false; }

		if ((model.ProvinceId == null || model.ProvinceId == "" || model.ProvinceId < 1) && (model.NewProvince == null || model.NewProvince == "")) {
			vModel.ProvinceId = true;
			ErrorList.push("Province must be defined !.");
		} else { vModel.ProvinceId = false; }

		if ((model.CityId == null || model.CityId == "" || model.CityId < 1) && (model.NewCity == null || model.NewCity == "")) {
			vModel.CityId = true;
			ErrorList.push("City must be defined !.");
		} else { vModel.CityId = false; }

		if (parseInt(model.OwnerShipPercentage) >= 25 && model.AuthOwnerTypeStr == ClientType.Personal) {
			if ((model.IsIdVerification1 && model.IdentificationMethodId1 != null) && (model.IdentityVerifyingPersonId1 == null || model.IdentityVerifyingPersonId1 == "" || model == null)) {
				vModel2.IdentityVerifyingPersonId1 = true;
				ErrorList.push("Verifying Person 1 must be defined !.");
			} else { vModel2.IdentityVerifyingPersonId1 = false; }

			// Identity number 1 check by IdentityTypeId  -> must for personal

			if (model.IdentificationMethodId1 == null || model.IdentificationMethodId1 == "") {
				vModel.IdentificationMethodId1 = true;
				ErrorList.push("Identification Method 1 must be defined !.");
			} else { vModel.IdentificationMethodId1 = false; }

			if (model.IdentityTypeId1 == null || model.IdentityTypeId1 == "") {
				vModel.IdentityTypeId1 = true;
				ErrorList.push("Identification Type 1 must be defined !.");
			} else { vModel.IdentityTypeId1 = false; }

			if (model.IdentityNumber1 == null || model.IdentityNumber1 == "") {
				vModel.IdentityNumber1 = true;
				ErrorList.push("Identity Number 1 must be defined !.");
			} else { vModel.IdentityNumber1 = false; }

			if (model.IdentityPlaceOfIssue1 == null || model.IdentityPlaceOfIssue1 == "") {
				vModel.IdentityPlaceOfIssue1 = true;
				ErrorList.push("Identity Place Of Issue 1 must be defined !.");
			} else { vModel.IdentityPlaceOfIssue1 = false; }
			if (model.IdentityTypeId1 == model.IdentityTypeId2) {
				vModel.IdentityTypeId1 = true;
				ErrorList.push("Identification Types must be different !.");
			} else { vModel.IdentityTypeId1 = false; }

			if (model.IdentityNumber1 == model.IdentityNumber2) {
				vModel.IdentityNumber1 = true;
				ErrorList.push("Identification Numbers must be different !.");
			} else { vModel.IdentityNumber1 = false; }

			if (model.IdentityExpireDate1 == null || model.IdentityExpireDate1 == "") {
				vModel.IdentityExpireDate1 = true;
				ErrorList.push("Identity Expire Date 1 must be defined !.");
			} else {
				vModel.IdentityExpireDate1 = false;
				model.IdentityExpireDate1 = DateHelper.ToMoment(model.IdentityExpireDate1);
				if (!model.IdentityExpireDate1.isValid()) {
					vModel.IdentityExpireDate1 = true;
					ErrorList.push("Identity Expire Date 1 must be valid !.");
				} else { vModel.IdentityExpireDate1 = false; }

				var interval1 = model.IdentityExpireDate1.diff(DateHelper.GetDate(), "days");
				if (interval1 < 30) {
					ErrorList.push("Identity Expire Date 1 must be valid In next 30 days !");
				}
			}

			if (model.IdentityTypeIdStr1 == null) {
				vModel.IdentityTypeIdStr1 = true;
				ErrorList.push("Identity Type 1 must be valid !");
			} else { vModel.IdentityTypeIdStr1 = false; }

			if ((model.IsIdVerification2 && model.IdentificationMethodId2 != null) && (model.IdentityVerifyingPersonId2 == null || model.IdentityVerifyingPersonId2 == "" || model == null)) {
				vModel2.IdentityVerifyingPersonId2 = true;
				ErrorList.push("Verifying Person 2 must be defined !.");
			} else { vModel2.IdentityVerifyingPersonId2 = false; }
			// Identity number check by IdentityTypeId  -> Optional


			// if (model.IdentificationMethodId2 == null || model.IdentificationMethodId2 == "") {
			// 	vModel2.IdentificationMethodId2 = true;
			// 	ErrorList.push("Identification Method 2 must be defined !.");
			// } else { vModel2.IdentificationMethodId2 = false; }

			// if (model.IdentityTypeId2 == null || model.IdentityTypeId2 == "") {
			// 	vModel2.IdentityTypeId2 = true;
			// 	ErrorList.push("Identification Type 2 must be defined !.");
			// } else { vModel2.IdentityTypeId2 = false; }

			// if (model.IdentityNumber2 == null || model.IdentityNumber2 == "") {
			// 	vModel2.IdentityNumber2 = true;
			// 	ErrorList.push("Identity Number 2 must be defined !.");
			// } else { vModel2.IdentityNumber2 = false; }

			// if (model.IdentityPlaceOfIssue2 == null || model.IdentityPlaceOfIssue2 == "") {
			// 	vModel2.IdentityPlaceOfIssue2 = true;
			// 	ErrorList.push("Identity Place Of Issue 2 must be defined !.");
			// } else { vModel2.IdentityPlaceOfIssue2 = false; }

			// if (model.IdentityExpireDate2 == null || model.IdentityExpireDate2 == "") {
			// 	vModel2.IdentityExpireDate2 = true;
			// 	ErrorList.push("Identity Expire Date 2 must be defined !.");
			// } else {
			// 	vModel2.IdentityExpireDate2 = false;
			// 	model.IdentityExpireDate2 = DateHelper.ToMoment(model.IdentityExpireDate2);
			// 	if (!model.IdentityExpireDate2.isValid()) {
			// 		ErrorList.push("Identity Expire Date 2 must be valid !.");
			// 	}
			// 	var interval2 = model.IdentityExpireDate2.diff(DateHelper.GetDate(), "days");
			// 	if (interval2 < 30) {
			// 		ErrorList.push("Identity Expire Date 2 must be valid In next 30 days !");
			// 	}
			// }

			// if (model.IdentityTypeIdStr2 == null) {
			// 	ErrorList.push("Identity Type 2 must be valid !");
			// }

		}

		this.setState({ vModel2, vModel })
		if (ErrorList.length > 0) {
			this.ShowMessageNode("warning", "Please fill required fields!", ErrorList.slice(0, 30).map((x, i) => <div key={i}>{x}</div>));
			this.setState({ isLoading: false });
			return false;
		}
		if ((model.NewCity != null && model.NewCity != "") || (model.NewProvince != null && model.NewProvince != "" && model.NewCity != null && model.NewCity != "")) {
			this.InsertCanadaPost()
		} else {
			this.UpdateModel();
		}

	}
	TreeDisplay = (record) => {
		var list = this.props.paramBankCustomerCorporateAuthOwnerList
		var level = 0;
		var currentNodePrimaryId;
			list.forEach(item => {
				if (record.PrimaryID == item.Id){
						level++;
						currentNodePrimaryId = item.PrimaryID;
				}	
			});
			list.forEach(item => {
				if (currentNodePrimaryId == item.Id){
						level++;	
				}	
			});
		if(record.FirstName == null){return "\xa0\xa0\xa0\xa0\xa0\xa0".repeat(level)+"► "+record.NameOfBusiness}
		else{return "\xa0\xa0\xa0\xa0\xa0\xa0".repeat(level)+"► "+record.FirstName + " " + record.LastName}
	}

	ShowMessageNode = (type, title, message) => {
		this.setState({
			alert: <GenericAlert Title={title} MessageNode={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	FilterIdentityNumber = (number) => {
		if (number == null) return "";

		var regexp = new RegExp("(.*)(.{4})");
		var onEk = regexp.exec(number)[1].replace(/([^ ])/g, "*");
		var sonEk = regexp.exec(number)[2];

		return onEk + sonEk;
	}

	ValidateEmail = (email) => {
		var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}
	handleChange = (name, newValue, data) => {
		this.setState(function (state, props) {
			var temp = state.corporateAuthOwnerModel || {};
			temp[name] = newValue;
			if (name == "IdentificationMethodId1" && data != null) {
				if (data.ParameterValue == IdentificationMethod.InPersonIDVerification) {
					temp.IsIdVerification1 = true;
				} else {
					temp.IsIdVerification1 = false;
					temp.IdentityVerifyingPersonId1 = null;
					temp.IdentificationMethod1 = null;
				}
			}
			if (name == "IdentificationMethodId2" && data != null) {
				if (data.ParameterValue == IdentificationMethod.InPersonIDVerification) {
					temp.IsIdVerification2 = true;
				} else {
					temp.IsIdVerification2 = false;
					temp.IdentityVerifyingPersonId2 = null;
					temp.IdentificationMethod2 = null;
				}
			}
			if (name == "IdentityTypeId1") {
				temp.IdentityTypeIdStr1 = data && data.ParameterValue;
				temp.IdentityNumber1 = null;
			}
			if (name == "IdentityTypeId2") {
				temp.IdentityTypeIdStr2 = data && data.ParameterValue;
				temp.IdentityNumber2 = null;
			}
			if (name == "AuthOwnerTypeId" && data != null) {
				temp.AuthOwnerTypeStr = data.ParameterValue;
			}
			if (name == "AuthOwnerTypeId") {
				this.setState({ vModel: {}, vModel2: {} })
			}
			if (name == "CountryId") {
				temp.ProvinceId = 0;
				temp.CityId = 0;
			}
			if (name == "ProvinceId") {
				temp.CityId = 0;
			}
			return { corporateAuthOwnerModel: temp };
		});
	}
	InsertCanadaPost = () => {

		const model = this.state.corporateAuthOwnerModel;
		let insertModel = {
			CountryId: model.CountryId,
			ProvinceId: model.ProvinceId,
			ProvinceName: model.NewProvince,
			City: model.NewCity
		};
		this.setState({ isLoading: true });
		Proxy.POST(
			"/coreapi/v1.0/Country/CanadaPost",
			insertModel,
			responseData => {
				this.setState({ isLoading: false });
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Canada Post Error", responseData.ErrorDescription);
					return;
				}
				if (responseData.Item)
					this.setState(function (state, props) {
						var temp = state.corporateAuthOwnerModel || {};
						temp.ProvinceId = responseData.Item.ProvinceId;
						temp.CityId = responseData.Item.CityId;
						return { corporateAuthOwnerModel: temp };
					}, this.UpdateModel);

			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Canada Post Error", error);
			}
		);
	}
	UpdateModel = () => {
		var temp = this.state.corporateAuthOwnerModel;
		const global = this.props.paramGlobalModel;
		if (global == null) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		if (global.Id == null || global.Id == 0) {
			this.ShowMessage("error", "Error", "Client must be selected");
			return;
		}
		temp.UniqueClientId = global.Id;

		this.setState({ isLoading: true });
		Proxy.POST(
			"/bankapi/v1.0/CustomerCorporateAuthOwner/Update",
			temp,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					console.log(responseData);
					return;
				}
				this.setState({ isLoading: false });
				this.ShowMessage("success", "Success", temp.Id > 0 ? "Beneficiary Ownership Updated ! " : "Beneficiary Ownership Saved ! ");
				this.ClearData();
				this.props.GetCustomerCorporateAuthOwnerModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", "ErrorCode: "+error);
			}
		);
	}



	DeleteModel = (model) => {
		if (model.Id == null || model.Id == 0) {
			this.ShowMessage("error", "Error", "Client Beneficiary Ownership Definition must be selected for delete operation !");
			return;
		}
		this.setState({ isLoading: true });
		Proxy.GET(
			"/bankapi/v1.0/CustomerCorporateAuthOwner/Delete/Id?Id=" + model.Id,
			responseData => {
				if (!responseData.IsSucceeded) {
					this.ShowMessage("error", "Error", responseData.ErrorDescription);
					return;
				}
				this.ShowMessage("success", "Success", "Beneficiary Ownership Definition deleted !");
				this.ClearData();
				this.props.GetCustomerCorporateAuthOwnerModels();
			},
			error => {
				this.setState({ isLoading: false });
				this.ShowMessage("error", "Error", error);
			}
		);
	}
	SelectedRowChange = (e, index) => {
		const model = this.props.paramBankCustomerCorporateAuthOwnerList[index];
		if (model != null && model.IdentityType1 != null) {
			model.IdentityTypeIdStr1 = model.IdentityType1.ParameterValue;
		}
		if (model != null && model.IdentityType2 != null) {
			model.IdentityTypeIdStr2 = model.IdentityType2.ParameterValue;
		}
		if (model != null) {
			model.AuthOwnerTypeStr = model.AuthOwnerType == null ? "" : model.AuthOwnerType.ParameterValue;
		}
		this.setState({ corporateAuthOwnerModel: model});
		
	}
	ClearData = () => {
		const global = { ...this.props.paramGlobalModel };
		var model = { Id: 0, UniqueClientId: global.Id };
		this.setState({ corporateAuthOwnerModel: model, vModel: {}, vModel2: {}});
	}

	showQuestionMessage = (message, model) => {
		this.setState({
			alert: <GenericAlert Title="Delete" Message={message} Type="warning" ShowCancel={true} OnCancel={() => this.hideAlert()} OnConfirm={() => this.DeleteModel(model)} />
		});
		this.setState({ isLoading: false });
	}
	ShowMessage = (type, title, message) => {
		// type:-success->warning->info->error
		this.setState({
			alert: <GenericAlert Title={title} Message={message} Type={type} OnConfirm={() => this.hideAlert()} />
		});
		this.setState({ isLoading: false });
	}
	RenderClientNameSelect = (d) => {
		return d.Id + " - " + (d.FirstName ? (d.FirstName + " " + d.LastName) : d.NameOfBusiness);
	}
	hideAlert = () => {
		this.setState({
			alert: null,
			isLoading: false
		});
	}
	render() {
		const { Disabled: DisabledProp, paramGlobalModel, paramBankCustomerCorporateAuthOwnerList } = this.props;
		const { corporateAuthOwnerModel, vModel, vModel2 } = this.state;
		const Disabled = DisabledProp || this.state.CustomDisabled;
		const DisabledForCorporate = DisabledProp || this.state.CustomDisabled || corporateAuthOwnerModel.AuthOwnerTypeStr == ClientType.Corporate;

		return (
			<GridContainer spacing={16}>
				{this.state.alert}
				<LoadingComponent Show={this.state.isLoading} />
				
				<Card className="no-radius">
					<CardBody>
						<GridContainer spacing={16}>
							<GridItem xs={4}>
									<GenericSelectInput
										// Required
										key={new Date().getTime()}
										Name="PrimaryId"
										LabelText="Primary Beneficiary"
										StaticData={this.props.paramBankCustomerCorporateAuthOwnerList}
										KeyValueMember="Id"
										Value={corporateAuthOwnerModel.PrimaryId}
										TextValueMember={"Id"}
										ValueChanged={this.handleChange} 
										RenderItem={this.RenderClientNameSelect}
										IsInvalid={vModel.PrimaryId}
									/>
								<GenericSelectInput
									Name="AuthOwnerTypeId"
									LabelText="Owner Type"
									Value={corporateAuthOwnerModel.AuthOwnerTypeId}
									ValueChanged={this.handleChange}
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterClientTypeC}
									Disabled={Disabled}
									DataRoot="Item"
									Required
									IsInvalid={vModel.AuthOwnerTypeId}
								/>
								<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLength200}
									Name="CUID"
									LabelText="CUID"
									Value={corporateAuthOwnerModel.CUID}
									ValueChanged={this.handleChange}
								/>
								{(corporateAuthOwnerModel.AuthOwnerTypeId == 8012) && (

									<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLength30}
									Name="NameOfBusiness"
									LabelText="Legal Name Of Business"
									Value={corporateAuthOwnerModel.NameOfBusiness}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.NameOfBusiness}
								/>
								)}
								{(corporateAuthOwnerModel.AuthOwnerTypeId == 8012) && (
									<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLength30}
									Name="BusinessType"
									LabelText="Doing business as"
									Value={corporateAuthOwnerModel.BusinessType}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.BusinessType}
								/>
								)}
								{(corporateAuthOwnerModel.AuthOwnerTypeId == 8011) && (
								<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLength30}
									Name="FirstName"
									LabelText="First Name"
									Value={corporateAuthOwnerModel.FirstName}
									ValueChanged={this.handleChange}
									IsInvalid={vModel.FirstName}
								/>
								)}
								{(corporateAuthOwnerModel.AuthOwnerTypeId == 8011) && (
									<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLength30}
									Name="MiddleName"
									LabelText="Middle Name"
									Value={corporateAuthOwnerModel.MiddleName}
									ValueChanged={this.handleChange}
									IsInvalid={vModel.MiddleName}
								/>
								)}
								{(corporateAuthOwnerModel.AuthOwnerTypeId == 8011) && (
								<GenericTextInput
									Disabled={Disabled}
									inputProps={this.ParameterMaxLength30}
									Name="LastName"
									LabelText="Last Name"
									Value={corporateAuthOwnerModel.LastName}
									ValueChanged={this.handleChange}
									IsInvalid={vModel.LastName}
								/>
								)}
								<GenericNumberInput
									Disabled={Disabled}
									MaxValue={100}
									DecimalScale={0}
									Name="OwnerShipPercentage"
									LabelText="% of Ownership"
									Value={corporateAuthOwnerModel.OwnerShipPercentage}
									ValueChanged={this.handleChange}
									Required
									IsInvalid={vModel.OwnerShipPercentage}
								/>
								{(corporateAuthOwnerModel.AuthOwnerTypeId == 8011) && (
								<GenericDateInput
									Utc
									Disabled={Disabled}
									IsPast={true}
									Name="DateOfBith"
									LabelText="Date Of Birth"
									Value={corporateAuthOwnerModel.DateOfBith == null ? "" : DateHelper.ToDateInputValue(corporateAuthOwnerModel.DateOfBith)}
									ValueChanged={this.handleChange} />
								)}
								{corporateAuthOwnerModel.AuthOwnerTypeStr == ClientType.Personal && (
									<>
										<GenericTextInput
											Disabled={Disabled}
											inputProps={this.ParameterMaxLength200}
											Name="DetailedOccupation"
											LabelText="Detailed Occupation"
											Value={corporateAuthOwnerModel.DetailedOccupation}
											ValueChanged={this.handleChange} />

									</>
								)}
								{corporateAuthOwnerModel.AuthOwnerTypeStr == ClientType.Corporate && (
									<>
										<GenericDateInput
											Utc
											Disabled={Disabled}
											Name="BusinessRegistrationDate"
											LabelText="Business Registration Date"
											Value={corporateAuthOwnerModel.BusinessRegistrationDate == null ? "" : DateHelper.ToDateInputValue(corporateAuthOwnerModel.BusinessRegistrationDate)}
											ValueChanged={this.handleChange} />
									</>
								)}
								<CanadaPostAddressComponent
									key={this.state.corporateAuthOwnerModel?.Id + "CanadaPostAddressComponent"}
									isZipCodeOptional={this.state.isZipCodeOptional}
									vModel={this.state.vModel}
									GridXs={4}
									GridCount={0}
									addressModel={this.state.corporateAuthOwnerModel}
									Disabled={Disabled}
									handleChange={this.handleChange}
								/>
								{(corporateAuthOwnerModel.AuthOwnerTypeId == 8011) && (
								<GenericTextInput
									Disabled={DisabledForCorporate}
									inputProps={{ maxLength: 15 }}
									Name="Occupation"
									LabelText="Occupation"
									Value={corporateAuthOwnerModel.Occupation}
									ValueChanged={this.handleChange}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel.Occupation : false}
									Required
								/>								
								)}
							</GridItem>
							{(corporateAuthOwnerModel.AuthOwnerTypeId == 8011) && (
								<GridItem xs={4}>
								<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Identification Method 1"></GenericLabel>
								<GenericSelectInput
									Disabled={DisabledForCorporate}
									Name="IdentificationMethodId1"
									LabelText="Identification Method"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterIdentificationMethod}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={corporateAuthOwnerModel.IdentificationMethodId1}
									ValueChanged={this.handleChange}
									Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel.IdentificationMethodId1 : false}
								/>
								{((corporateAuthOwnerModel.IsIdVerification1) || (corporateAuthOwnerModel.IdentificationMethod1 != null && corporateAuthOwnerModel.IdentificationMethod1.ParameterValue == IdentificationMethod.InPersonIDVerification)) && (
									<GenericSelectInput
										Disabled={DisabledForCorporate}
										Name="IdentityVerifyingPersonId1"
										LabelText="Verifying Person"
										Value={corporateAuthOwnerModel.IdentityVerifyingPersonId1 || ""}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterIdentityVerifyingPerson}
										DataRoot="Item"
										Required={((!DisabledForCorporate && corporateAuthOwnerModel.IdentificationMethod1 != null) || (corporateAuthOwnerModel != null && corporateAuthOwnerModel.IsIdVerification1)) && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
										IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityVerifyingPersonId1 : false}
									/>
								)}
								<GenericSelectInput
									Disabled={DisabledForCorporate}
									Name="IdentityTypeId1"
									LabelText="Identification Type"
									Method="GET"
									Url="/bankapi/v1.0/CustomerCorporateAuthOwner/GetIdentityType"
									Parameter={this.ParameterIdentityType}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Sorted={this.sortedParameterDesc}
									Value={corporateAuthOwnerModel.IdentityTypeId1}
									ValueChanged={this.handleChange}
									Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel.IdentityTypeId1 : false}
								/>
								{(paramGlobalModel.IsExceptClientSetup == true) ? (
									<GenericTextInput
										Disabled={true}
										Name="IdentityNumber1"
										LabelText="Identification No."
										Value={this.FilterIdentityNumber(corporateAuthOwnerModel.IdentityNumber1)}
										ValueChanged={this.handleChange}
										Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
										IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel.IdentityNumber1 : false}
									/>
								) : (
										<GenericTextInput
											Disabled={DisabledForCorporate}
											inputProps={this.ParameterMaxLength20}
											Name="IdentityNumber1"
											LabelText="Identification No."
											Value={corporateAuthOwnerModel.IdentityNumber1}
											ValueChanged={this.handleChange}
											Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
											IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel.IdentityNumber1 : false}
										/>
									)}
								<GenericTextInput
									Disabled={DisabledForCorporate}
									inputProps={{ maxLength: 15 }}
									Name="IdentityPlaceOfIssue1"
									LabelText="ID Country of Issue"
									Value={corporateAuthOwnerModel.IdentityPlaceOfIssue1}
									ValueChanged={this.handleChange}
									Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel.IdentityPlaceOfIssue1 : false}
								/>
								<GenericTextInput
									Disabled={DisabledForCorporate}
									inputProps={{ maxLength: 15 }}
									Name="IdentityProvinceOfIssue1"
									LabelText="ID Province/State of Issue"
									Value={corporateAuthOwnerModel.IdentityProvinceOfIssue1}
									ValueChanged={this.handleChange}
									Required={corporateAuthOwnerModel.IdentityPlaceOfIssue1 != null && (corporateAuthOwnerModel.IdentityPlaceOfIssue1.toUpperCase() === "CAD" || corporateAuthOwnerModel.IdentityPlaceOfIssue1.toUpperCase() === "USA" ||
										corporateAuthOwnerModel.IdentityPlaceOfIssue1.toUpperCase() === "CANADA" || corporateAuthOwnerModel.IdentityPlaceOfIssue1.toUpperCase() === "UNITED STATES OF AMERICA")}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityProvinceOfIssue1 : false}
								/>
								<GenericDateInput
									Utc
									Disabled={DisabledForCorporate}
									IsFuture={true}
									Name="IdentityExpireDate1"
									LabelText="ID Exp Date"
									Value={corporateAuthOwnerModel.IdentityExpireDate1 == null ? "" : Formatter.FormatDateUtc(corporateAuthOwnerModel.IdentityExpireDate1 || "")}
									ValueChanged={this.handleChange}
									Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel.IdentityExpireDate1 : false}
								/>
							</GridItem>
							)}
							{(corporateAuthOwnerModel.AuthOwnerTypeId == 8011) && (
								<GridItem xs={4}>
								<GenericLabel Bold={true} FontSize="14px" TextColor="black" Text="Identification Method 2"></GenericLabel>
								<GenericSelectInput
									Disabled={DisabledForCorporate}
									CanClear
									Name="IdentificationMethodId2"
									LabelText="Identification Method"
									Method="POST"
									Url="/coreapi/v1.0/Parameter/Search"
									Parameter={this.ParameterIdentificationMethod}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Value={corporateAuthOwnerModel.IdentificationMethodId2}
									ValueChanged={this.handleChange}
									Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentificationMethodId2 : false}
								/>
								{((corporateAuthOwnerModel.IsIdVerification2) || (corporateAuthOwnerModel.IdentificationMethod2 != null && corporateAuthOwnerModel.IdentificationMethod2.ParameterValue == IdentificationMethod.InPersonIDVerification)) && (
									<GenericSelectInput
										CanClear
										Disabled={DisabledForCorporate}
										Name="IdentityVerifyingPersonId2"
										LabelText="Verifying Person"
										Value={corporateAuthOwnerModel.IdentityVerifyingPersonId2 || ""}
										ValueChanged={this.handleChange}
										KeyValueMember="Id"
										TextValueMember="ParameterDesc"
										Method="POST"
										Url="/coreapi/v1.0/Parameter/Search"
										Parameter={this.ParameterIdentityVerifyingPerson}
										DataRoot="Item"
										// Required={((!DisabledForCorporate && corporateAuthOwnerModel.IdentificationMethod2 != null) || (corporateAuthOwnerModel != null && corporateAuthOwnerModel.IsIdVerification2)) && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
										IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityVerifyingPersonId2 : false}
									/>
								)}
								<GenericSelectInput
									CanClear
									Disabled={DisabledForCorporate}
									Name="IdentityTypeId2"
									LabelText="Identification Type"
									Method="GET"
									Url="/bankapi/v1.0/CustomerCorporateAuthOwner/GetIdentityType"
									Parameter={this.ParameterIdentityType}
									DataRoot="Item"
									KeyValueMember="Id"
									TextValueMember="ParameterDesc"
									Sorted={this.sortedParameterDesc}
									Value={corporateAuthOwnerModel.IdentityTypeId2}
									ValueChanged={this.handleChange}
									// Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityTypeId2 : false}
								/>
								{(paramGlobalModel.IsExceptClientSetup == true) ? (
									<GenericTextInput
										Disabled={true}
										Name="IdentityNumber2"
										LabelText="Identification No."
										Value={this.FilterIdentityNumber(corporateAuthOwnerModel.IdentityNumber2)}
										ValueChanged={this.handleChange}
										// Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
										IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityNumber2 : false}

									/>
								) : (
										<GenericTextInput
											Disabled={DisabledForCorporate}
											inputProps={this.ParameterMaxLength20}
											Name="IdentityNumber2"
											LabelText="Identification No."
											Value={corporateAuthOwnerModel.IdentityNumber2}
											ValueChanged={this.handleChange}
											// Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
											IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityNumber2 : false}
										/>
									)}

								<GenericTextInput
									Disabled={DisabledForCorporate}
									inputProps={{ maxLength: 15 }}
									Name="IdentityPlaceOfIssue2"
									LabelText="ID Country of Issue"
									Value={corporateAuthOwnerModel.IdentityPlaceOfIssue2}
									ValueChanged={this.handleChange}
									// Required={!DisabledForCorporate ? true : false}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityPlaceOfIssue2 : false}
								/>
								<GenericTextInput
									Disabled={DisabledForCorporate}
									inputProps={{ maxLength: 15 }}
									Name="IdentityProvinceOfIssue2"
									LabelText="ID Province/State of Issue"
									Value={corporateAuthOwnerModel.IdentityProvinceOfIssue2}
									ValueChanged={this.handleChange}
									// Required={corporateAuthOwnerModel.IdentityPlaceOfIssue2 != null && (corporateAuthOwnerModel.IdentityPlaceOfIssue2.toUpperCase() === "CAD" || corporateAuthOwnerModel.IdentityPlaceOfIssue2.toUpperCase() === "USA" ||
									// 	corporateAuthOwnerModel.IdentityPlaceOfIssue2.toUpperCase() === "CANADA" || corporateAuthOwnerModel.IdentityPlaceOfIssue2.toUpperCase() === "UNITED STATES OF AMERICA")}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityProvinceOfIssue2 : false}
								/>
								<GenericDateInput
									Utc
									Disabled={DisabledForCorporate}
									IsFuture={true}
									Name="IdentityExpireDate2"
									LabelText="ID Exp Date"
									Value={corporateAuthOwnerModel.IdentityExpireDate2 == null ? "" : Formatter.FormatDateUtc(corporateAuthOwnerModel.IdentityExpireDate2 || "")}
									ValueChanged={this.handleChange}
									Required={!DisabledForCorporate && parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? true : false}
									IsInvalid={parseInt(corporateAuthOwnerModel.OwnerShipPercentage) >= 25 ? vModel2.IdentityExpireDate2 : false}
								/>
							</GridItem>
								)}
						</GridContainer>
						<GridContainer justify="flex-end">
							<GridItem>
								<Button disabled={Disabled} size="sm" color="black" onClick={() => this.ClearData()}>	Clear	</Button>
								<Button disabled={Disabled} size="sm" color="black" onClick={() => this.OperationCheck()}>{ (corporateAuthOwnerModel.Id > 0 ? "Update" : "Add") }</Button>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>


				<Card className="no-radius">
					<CardBody>
						<GridItem xs={12}>
							<GridContainer spacing={16}>
								<GridItem xs={12}>
								{/* <Button disabled={Disabled} size="sm" color="black" onClick={() => this.AddComponent(187)}>	Add	</Button> */}
									<GenericGrid
										Data={paramBankCustomerCorporateAuthOwnerList}
										PageSize={5}
										HideButton={true}
										Columns={this.CloumnsData}
										RowSelected={index => {
											this.SelectedRowChange(0, index);
										}}
										IsSorted={false}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>
					</CardBody>
				</Card>
			</GridContainer >
		);
	}
}
CorporateAuthOwner.propTypes = {
	classes: PropTypes.object,
	addressModel: PropTypes.object,
	paramGlobalModel: PropTypes.object,
	paramCustomerProfile: PropTypes.object,
	paramBankCustomerAddressList: PropTypes.array,
	GetCustomerAddressModels: PropTypes.func,
	paramBankCustomerStoreList: PropTypes.array,
	handleChangeParentTab: PropTypes.func,
	handleChangeChildTab: PropTypes.func
};
export default withArtifex(CorporateAuthOwner, {});